import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/layouts/PageLayout';
import { SearchSelect } from '../../components/common/SearchSelect';
import { Column, TableWidget } from '../../components/widgets/TableWidget';
import { Search } from '@mui/icons-material';
import { StatWidget } from '../../components/widgets/StatWidget';
import { Customer } from '../../models/Customer';
import useAPI from '../../hooks/useAPI';
import { lineData, monthsData } from '../../utils/Utils';
import { useModal } from '../../contexts/ModalContext';

const customerColumn: Column[] = [
  { header: 'Nr.', accessor: 'id', align: 'center' },
  { header: 'Kund', accessor: 'name', align: 'left' },
  { header: 'Org.Nr', accessor: 'organisationNumber', align: 'left' },
  { header: 'Aktiva Projekt', accessor: 'activeProjects', align: 'center' },
  { header: 'Aktiva Ordrar', accessor: 'activeOrders', align: 'center' },
  { header: 'Försäljning', accessor: 'totalSale', align: 'center' },
  { header: 'Obetalt', accessor: 'unpaidAmount', align: 'right' },
];

const Company: React.FC = () => {
  // Use modal context
  const { open } = useModal();
  const [customerData, setCustomerData] = useState<Customer[]>([]);
  const { data, callAPI } = useAPI<Customer[]>(`/customer/type/COMPANY`);
  // Call api to fetch employees
  useEffect(() => {
    callAPI();
  }, []);
  // Populate table data
  useEffect(() => {
    if (data) {
      setCustomerData(data);
    }
  }, [data]);
  // handle OnEdit
  const handleOnEdit = () => {};
  // handle Create Offer
  const handleCreateKund = () => {
    open('CustomerModal');
  };

  return (
    <PageLayout
      title="Företagskunder"
      onEdit={handleOnEdit}
      extras={[
        <Button key="create" variant="contained" onClick={handleCreateKund}>
          Skapa
        </Button>,
        <SearchSelect
          key="search"
          inputStyle={{ background: 'white' }}
          placeholder="Sök"
          icon={<Search />}
          iconAlign="right"
        />,
      ]}
    >
      <StatWidget title="Omsättning" variant="bar" size={12} xAxis={monthsData} data={lineData} />
      <TableWidget
        title="Företagskunder Lista"
        size={12}
        data={customerData}
        columns={customerColumn}
      />
    </PageLayout>
  );
};

export default Company;

import { Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/layouts/PageLayout';
import { SearchSelect } from '../../components/common/SearchSelect';
import { Column, TableWidget } from '../../components/widgets/TableWidget';
import { Search } from '@mui/icons-material';
import { StatWidget } from '../../components/widgets/StatWidget';
import { monthsData } from '../../utils/Utils';
import useAPI from '../../hooks/useAPI';
import { Contract } from '../../models/Contract';
import { useModal } from '../../contexts/ModalContext';
import { useSearchParams } from 'react-router-dom';

import { PieDataItem, SortConfig, OfferRow } from './types';
import { offerRow, getMonthlyCounts, getNestedValue } from './utils';

/**
 * Table columns for the Offer table.
 */
const offerColumn: Column[] = [
  { header: 'Nr.', accessor: 'id', align: 'left' },
  { header: 'Kund', accessor: 'customer.name', align: 'left' },
  { header: 'Org.Nr', accessor: 'customer.identityNumber', align: 'left' },
  { header: 'Adress', accessor: 'fullAddress', align: 'left' },
  { header: 'Skapad', accessor: 'createdOn', align: 'left' },
  { header: 'Värde', accessor: 'totalValue', align: 'right' },
  { header: 'Status', accessor: 'status', align: 'right' },
  { header: '', accessor: 'colorStatus', align: 'right' },
];

const Offer: React.FC = () => {
  // Modal and query param handling
  const { open } = useModal();
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('id');
  const navigate = useNavigate();

  // Fetch contracts via custom hook
  const { data, error, callAPI } = useAPI<Contract[]>(`/contract?relations=${['customer']}`);

  // Table data, line/bar data, and pie data
  const [tableData, setTableData] = useState<OfferRow[]>([]);
  const [lineData, setLineData] = useState<number[]>([]);
  const [pieData, setPieData] = useState<PieDataItem[]>([
    { id: 1, value: 0, label: 'Skickade', color: '#345995' },
    { id: 2, value: 0, label: 'Godkända', color: '#0E8E70' },
    { id: 3, value: 0, label: 'Avvisade', color: '#C1666B' },
    { id: 4, value: 0, label: 'Förfallna', color: 'orange' }, // #FFF689
  ]);

  // Sorting state
  const [sortConfig, setSortConfig] = useState<SortConfig>();

  /**
   * Memoized sorted data based on the user’s chosen column and direction.
   */
  const sortedData = useMemo<OfferRow[]>(() => {
    if (!sortConfig) return tableData;
    const { key, direction } = sortConfig;

    return [...tableData].sort((a, b) => {
      const aValue = getNestedValue(a, key);
      const bValue = getNestedValue(b, key);

      // Compare as strings if both are strings
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }

      // Handle nullish
      if (aValue == null && bValue == null) return 0;
      if (aValue == null) return direction === 'asc' ? 1 : -1;
      if (bValue == null) return direction === 'asc' ? -1 : 1;

      // If they are numbers or dates, do a normal comparison
      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [tableData, sortConfig]);

  /**
   * Toggle sort direction or pick a new column to sort by.
   */
  const handleSort = (key: string) => {
    setSortConfig((prev) => {
      if (prev?.key === key) {
        // Flip direction if same key
        return {
          key,
          direction: prev.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      return { key, direction: 'asc' };
    });
  };

  // onRowClick: pass the row object, navigate to a details page
  const handleRowClick = (row: OfferRow) => {
    navigate(`/offers/${row.id}`);
  };

  // Open modal if 'id' param is present
  useEffect(() => {
    if (contractId) {
      open('ContractActionModal');
    }
  }, [contractId, open]);

  // Fetch data
  useEffect(() => {
    callAPI();
    // eslint-disable-next-line
  }, []);

  // Update pie data
  const updatePieData = (contracts: Contract[]) => {
    const updated = pieData.map((item) => {
      let count = 0;
      switch (item.label) {
        case 'Avvisade':
          count = contracts.filter((c) => c.status === 'Avvisad').length;
          break;
        case 'Godkända':
          count = contracts.filter((c) => c.status === 'Godkänd').length;
          break;
        case 'Skickade':
          count = contracts.filter((c) => c.status === 'Skickad').length;
          break;
        case 'Utkast':
          count = contracts.filter((c) => c.status === 'Utkast').length;
          break;
        case 'Förfallna':
          count = contracts.filter((c) => c.status === 'Förfallen').length;
          break;
      }
      return { ...item, value: count };
    });
    setPieData(updated);
  };

  // Handle incoming data
  useEffect(() => {
    if (data) {
      // Convert Contract -> OfferRow
      const offerRows = data.map((contract) => {
        // Mark contract as 'Förfallen' if validationPeriod < now
        if (new Date(contract.validationPeriod ?? '') < new Date()) {
          contract.status = 'Förfallen';
        }
        return offerRow(contract);
      });

      // Sort by ID ascending by default
      offerRows.sort((a, b) => {
        if (a.id === undefined && b.id === undefined) return 0;
        if (a.id === undefined) return 1;
        if (b.id === undefined) return -1;
        return a.id - b.id;
      });

      setTableData(offerRows);
      setLineData(getMonthlyCounts(data));
      updatePieData(data);
    }
  }, [data, error]);

  // Handlers
  const handleOnEdit = () => {};
  const handleCreateOffer = () => open('OfferModal');

  return (
    <PageLayout
      title="Offert"
      onEdit={handleOnEdit}
      extras={[
        <Button key="create" variant="contained" onClick={handleCreateOffer}>
          Skapa
        </Button>,
        <SearchSelect
          key="search"
          inputStyle={{ background: 'white' }}
          placeholder="Sök"
          iconAlign="right"
          icon={<Search />}
        />,
      ]}
    >
      {/* Bar/Line chart */}
      <StatWidget
        title="Antal skickade"
        variant="bar"
        size={7}
        xAxis={monthsData}
        data={lineData}
      />
      {/* Pie chart */}
      <StatWidget title="Status" variant="pie" size={5} data={pieData} />
      {/* Table */}
      <TableWidget
        title="Lista"
        size={12}
        data={sortedData}
        columns={offerColumn}
        onSort={handleSort}
        sortConfig={sortConfig}
        onRowClick={handleRowClick}
      />
    </PageLayout>
  );
};

export default Offer;

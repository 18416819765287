import React from 'react';
import { CardLayout } from '../../../components/layouts/CardLayout';
import { RowBox } from '../../../styles/commonStyles';
import { TextWrapper } from '../../../components/common/TextWrapper';
import { TextField } from '../../../components/common/TextField';
import { EmployeeForm } from '../../../models/EmployeeForm';

interface EmployeeContactSectionProps {
  /**
   * The current employee form data.
   */
  employee: EmployeeForm;

  /**
   * A callback to update the main state in the parent modal.
   */
  onUpdate: (updatedData: Partial<EmployeeForm>) => void;
}

/**
 * Displays the "Kontaktuppgifter" (Contact Information) section
 * for creating/editing an Employee.
 */
export const EmployeeContactSection: React.FC<EmployeeContactSectionProps> = ({
  employee,
  onUpdate,
}) => {
  /**
   * Handles changes for all contact-related fields.
   */
  const handleContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(`Updated field: ${name}, Value: ${value}`); // Debug log
    onUpdate({ [name]: value }); // Pass updated field to parent
  };

  return (
    <CardLayout title="Kontaktuppgifter" index={2}>
      {/* Address */}
      <RowBox>
        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Adress" labelPosition="top">
          <TextField name="address" value={employee.address} onChange={handleContactChange} />
        </TextWrapper>
        {/* Post code */}
        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Postnr" labelPosition="top">
          <TextField name="postCode" value={employee.postCode} onChange={handleContactChange} />
        </TextWrapper>
      </RowBox>

      {/* City */}
      <RowBox>
        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Ort" labelPosition="top">
          <TextField name="city" value={employee.city} onChange={handleContactChange} />
        </TextWrapper>

        {/* Phone */}
        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Telefon" labelPosition="top">
          <TextField name="tel" value={employee.tel} onChange={handleContactChange} />
        </TextWrapper>
      </RowBox>

      {/* Email */}
      <RowBox>
        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="E-post" labelPosition="top">
          <TextField name="email" value={employee.email} onChange={handleContactChange} />
        </TextWrapper>
      </RowBox>
    </CardLayout>
  );
};

import React, { useState, KeyboardEvent, ChangeEvent } from 'react';
import { TextField, Chip, Box, styled } from '@mui/material';

/**
 * Props for the TagField component.
 */
interface TagProps {
  /**
   * Initial list of tags to display.
   */
  tags?: string[];

  /**
   * Placeholder text for the input field.
   */
  title?: string;

  /**
   * Custom styles for the tag container.
   */
  sx?: object;

  /**
   * The name of the field, useful for form handling.
   */
  name?: string;

  /**
   * Callback function triggered when the tags change.
   * @param {string[]} tags - The updated list of tags.
   * @param {string} [name] - The name of the field.
   */
  onChange: (tags: string[], name?: string) => void;
}

/**
 * A component that allows users to enter, display, and delete tags.
 *
 * @param {TagProps} props - The props for the TagField component.
 * @returns {React.ReactElement} The rendered TagField component.
 *
 * @example
 * <TagField
 *    title="Add a tag"
 *    tags={['ExampleTag']}
 *    onChange={(updatedTags) => console.log(updatedTags)}
 * />
 */
export const TagField: React.FC<TagProps> = ({
  sx,
  onChange,
  title = 'enter',
  tags = [],
  name,
}) => {
  // State to manage the current list of tags
  const [tag, setTag] = useState<string[]>(tags);

  // State to manage the input value
  const [inputValue, setInputValue] = useState<string>('');

  /**
   * Handles the Enter key event to add a new tag.
   *
   * @param {KeyboardEvent<HTMLDivElement>} event - The keyboard event.
   */
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      if (!tag.includes(inputValue.trim())) {
        const updatedTags = [...tag, inputValue.trim()];
        setTag(updatedTags);
        onChange(updatedTags, name);
      }
      setInputValue(''); // Clear the input
    }
  };

  /**
   * Handles the deletion of a tag.
   *
   * @param {string} tagToDelete - The tag to delete.
   */
  const handleDelete = (tagToDelete: string) => {
    const updatedTags = tag.filter((value) => value !== tagToDelete);
    setTag(updatedTags);
    onChange(updatedTags, name);
  };

  /**
   * Handles changes to the input value.
   *
   * @param {ChangeEvent<HTMLInputElement>} event - The input change event.
   */
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <TagContainer sx={sx}>
      {/* Render existing tags as chips */}
      {tag.map((tag, index) => (
        <Chip
          key={index}
          label={tag}
          onDelete={() => handleDelete(tag)}
          color="primary"
          sx={{ marginRight: 1, marginBottom: 1 }}
        />
      ))}

      {/* Input field for adding new tags */}
      <TextField
        placeholder={title}
        variant="standard"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        sx={{ flexGrow: 1, width: '100%' }}
        slotProps={{
          input: {
            disableUnderline: true,
          },
        }}
      />
    </TagContainer>
  );
};

/**
 * Styled container for the tags and input field.
 */
const TagContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: '8px',
  width: '100%',
  padding: '8px 10px 8px 10px',
}));

import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Divider, Typography } from '@mui/material';
import { RowBox } from '../../styles/commonStyles';
import { WidgetLayout, WidgetProps } from '../layouts/WidgetLayout';

/**
 * Props for the Reminder component.
 */
interface ReminderProps extends WidgetProps {
  /**
   * The title of the reminder.
   */
  title: string;

  /**
   * A brief description of the reminder.
   */
  description: string;

  /**
   * When the reminder is due or scheduled.
   */
  when: string;

  /**
   * Whether to hide the header of the widget.
   * Defaults to `true`.
   */
  hideHeader?: boolean;

  /**
   * An optional icon to display in the reminder.
   */
  icon?: React.ReactNode;
}

/**
 * A reminder widget that displays a title, description, and a due date or time.
 *
 * @param {ReminderProps} props - The props for the Reminder component.
 * @returns {React.ReactElement} The rendered Reminder component.
 *
 * @example
 * <Reminder
 *    title="Meeting Reminder"
 *    description="Discuss project updates with the team."
 *    when="Today at 3 PM"
 *    icon={<CalendarIcon />}
 *    size={4}
 * />
 */
export const Reminder: React.FC<ReminderProps> = ({
  title,
  description,
  when,
  size,
  icon,
  hideHeader = true,
}) => {
  const theme = useTheme();

  return (
    <WidgetLayout size={size} hideHeader={hideHeader} disablePadding={true}>
      <ReminderContainer>
        {/* Header Row with Title and Optional Icon */}
        <RowBox sx={{ justifyContent: 'space-between', padding: '10px' }}>
          <Typography color="white" fontWeight="bold">
            {title}
          </Typography>
          {icon}
        </RowBox>

        {/* Divider */}
        <Divider
          sx={{
            width: '97%',
            margin: '0 2%',
            bgcolor: theme.palette.customColors.color3,
          }}
        />

        {/* Description */}
        <Typography fontWeight="bold" color="white" fontSize="14px" padding="10px">
          {description}
        </Typography>

        {/* Due Date or Time */}
        <Typography fontSize="12px" color="white" padding="10px">
          {when}
        </Typography>
      </ReminderContainer>
    </WidgetLayout>
  );
};

/**
 * Styled container for the reminder content.
 */
const ReminderContainer = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  padding: '10px',
  background: theme.palette.customColors.color1,
  display: 'flex',
  flexFlow: 'column',
  '&:hover': {
    cursor: 'pointer', // Change cursor on hover
  },
  '&:active': {
    background: 'grey', // Change background when active
  },
}));

import React from 'react';
import PageLayout from '../../components/layouts/PageLayout';
import { StatWidget } from '../../components/widgets/StatWidget';
import { Column, TableWidget } from '../../components/widgets/TableWidget';

function createData(name: string, id: number, hours: number) {
  return { name, id, hours };
}

const rows = [
  createData('Genc Sadiku', 1123, 142),
  createData('Lewis Hamilton', 1123, 142),
  createData('Sebastian Vettel', 1123, 142),
  createData('Max Verstappen', 1123, 142),
  createData('Fernando Alonso', 1123, 142),
  createData('Mark Webber', 1123, 142),
];
const column: Column[] = [
  { header: 'Name', accessor: 'name', align: 'left' },
  { header: 'ID', accessor: 'id', align: 'left' },
  { header: 'Hours', accessor: 'hours', align: 'right' },
];

const biggestInvoice = [
  { id: 0, value: 890, label: 'Akademiklinike' },
  { id: 1, value: 1200, label: 'Smart Solution' },
  { id: 2, value: 3500, label: 'Akea' },
  { id: 3, value: 1020, label: 'GESHDO' },
  { id: 4, value: 1200, label: 'IKEA' },
];

const costsLastYear = [
  { id: 0, value: 890, label: 'Löner' },
  { id: 1, value: 300, label: 'IT-tjänster' },
  { id: 2, value: 800, label: 'Hyra' },
  { id: 3, value: 1020, label: 'Förmåner' },
  { id: 4, value: 600, label: 'Övrigt' },
];

const lineData = [12000, 18000, 16000, 20000, 28000, 12000];

const Overview: React.FC = () => {
  return (
    <PageLayout
      title="Översikt"
      onEdit={() => {
        console.log('Test');
      }}
    >
      <StatWidget
        title="Störst fakturerat belopp (kr)"
        variant="pie"
        size={6}
        data={biggestInvoice}
      />
      <StatWidget
        title="Kostnader senaste 12 månader (kr)"
        variant="pie"
        size={6}
        data={costsLastYear}
      />
      <StatWidget
        title="Försäljning"
        variant="line"
        size={12}
        data={lineData}
        xAxis={[0, 500, 1000, 10000, 20000, 30000]}
      />
      <TableWidget data={rows} columns={column} size={12} title={'Medarbetare'}></TableWidget>
    </PageLayout>
  );
};

export default Overview;

import { Button } from '@mui/material';
import React from 'react';
import PageLayout from '../../components/layouts/PageLayout';
import { SearchSelect } from '../../components/common/SearchSelect';
import { Column, TableWidget } from '../../components/widgets/TableWidget';
import { StatWidget } from '../../components/widgets/StatWidget';
import { Circle, Search } from '@mui/icons-material';
import { lineData, monthsData } from '../../utils/Utils';
import { useModal } from '../../contexts/ModalContext';

interface CaseTable {
  id: number;
  name: string;
  orgNumber: string;
  order: number;
  address: string;
  createdBy: string;
  description: string;
  status: 'Pågående' | 'Avklarad';
  colorStatus: React.ReactNode;
}
// Status-to-Color Mapping
const statusColors: Record<CaseTable['status'], string> = {
  Avklarad: 'green',
  Pågående: 'orange',
};

function createCaseData(offer: Omit<CaseTable, 'colorStatus'>): CaseTable {
  const { status, ...rest } = offer;
  return {
    ...rest,
    status,
    colorStatus: <Circle sx={{ color: statusColors[status] }} />,
  };
}

const caseColumn: Column[] = [
  { header: 'Nr.', accessor: 'id', align: 'left' },
  { header: 'Kund', accessor: 'name', align: 'left' },
  { header: 'Org.Nr', accessor: 'orgNumber', align: 'left' },
  { header: 'Order', accessor: 'order', align: 'left' },
  { header: 'Adress', accessor: 'address', align: 'left' },
  { header: 'Inlagd av', accessor: 'createdBy', align: 'right' },
  { header: 'Beskrivning', accessor: 'description', align: 'left' },
  { header: 'Status', accessor: 'status', align: 'left' },
  { header: '', accessor: 'colorStatus', align: 'left' },
];

const caseData: CaseTable[] = [
  createCaseData({
    id: 1,
    name: 'Aina Hall',
    orgNumber: '12214-213',
    order: 12422,
    address: 'Kungsgatan 6, Malmö',
    createdBy: 'Elise Svensson',
    description: 'Description',
    status: 'Avklarad',
  }),
  createCaseData({
    id: 2,
    name: 'Akea',
    orgNumber: '12214-2133',
    order: 12422,
    address: 'Kungsgatan 6, Malmö',
    createdBy: 'Elise Svensson',
    description: 'Description',
    status: 'Pågående',
  }),
  createCaseData({
    id: 3,
    name: 'Aina Hall',
    orgNumber: '12214-213',
    order: 12422,
    address: 'Kungsgatan 6, Malmö',
    createdBy: 'Elise Svensson',
    description: 'Description',
    status: 'Pågående',
  }),
  createCaseData({
    id: 4,
    name: 'Aina Hall',
    orgNumber: '12214-213',
    order: 12422,
    address: 'Kungsgatan 6, Malmö',
    createdBy: 'Elise Svensson',
    description: 'Description',
    status: 'Pågående',
  }),
];

const pieData = [
  { id: 1000, value: 1, label: 'Avklarad', color: 'green' },
  { id: 1001, value: 3, label: 'Pågående', color: 'orange' },
];

const CaseManagement: React.FC = () => {
  // Use modal context
  const { open } = useModal();
  // handle OnEdit
  const handleOnEdit = () => {};
  // handle Create Case
  const handleCreateCase = () => {
    open('CaseModal');
  };

  return (
    <PageLayout
      title="Ärendehantering"
      onEdit={handleOnEdit}
      extras={[
        <Button key="create" variant="contained" onClick={handleCreateCase}>
          Skapa
        </Button>,
        <SearchSelect
          key="search"
          inputStyle={{ background: 'white' }}
          placeholder="Sök"
          icon={<Search />}
          iconAlign="right"
        />,
      ]}
    >
      <StatWidget title="Antal ärenden" variant="bar" size={7} xAxis={monthsData} data={lineData} />
      <StatWidget title="Status" variant="pie" size={5} data={pieData} />
      <TableWidget title="Ärenden Lista" size={12} data={caseData} columns={caseColumn} />
    </PageLayout>
  );
};

export default CaseManagement;

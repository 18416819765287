import { styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DatePicker as DatePickerMUI } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

/**
 * Props for the DatePicker component.
 */
interface DatePickerProps {
  /**
   * Callback function triggered when the date changes.
   * Expects a synthetic event-like object with target.name and target.value
   */
  onChange?: (event: any) => void;

  /**
   * The name of the input field, used to identify the field.
   */
  name?: string;

  /**
   * The current value of the date picker, formatted as "YYYY-MM-DD" or null/empty.
   */
  value?: string | null;

  /**
   * Position of the icon in the input field.
   * Options: `'start' | 'end'`. Defaults to `'start'`.
   */
  iconPosition?: 'start' | 'end';
}

/**
 * A styled DatePicker component using MUI that allows users to select a date.
 * We parse empty/invalid values as null => shows blank field instead of defaulting to "today."
 */
export const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  name,
  value = null, // default to null if no value
  iconPosition = 'start',
}) => {
  /**
   * A helper to parse the given string (YYYY-MM-DD) into a Dayjs object, or return null if invalid/empty.
   */
  const parseDate = (v: string | null) => {
    if (!v) return null; // if '', undefined, or null => no date
    const parsed = dayjs(v, 'YYYY-MM-DD', true); // 'true' => strict parse
    return parsed.isValid() ? parsed : null;
  };

  // Local state storing a Dayjs or null. Initialize once.
  const [internalValue, setInternalValue] = useState<Dayjs | null>(parseDate(value));

  // If the parent "value" changes, re-parse it. (e.g., switching from create to edit mode)
  useEffect(() => {
    setInternalValue(parseDate(value));
  }, [value]);

  /**
   * Handles the change event when a new date is selected or cleared.
   */
  const handleChange = (date: Dayjs | null) => {
    // If user clears => date = null => we store null
    const formattedValue = date ? date.format('YYYY-MM-DD') : null;
    setInternalValue(date);

    if (onChange) {
      onChange({
        target: {
          name,
          value: formattedValue,
        },
      });
    }
  };

  return (
    <DatePickerStyled
      onChange={handleChange}
      value={internalValue} // Bind the local Dayjs/null to the MUI picker
      slotProps={{
        inputAdornment: {
          position: iconPosition,
          style: { margin: '0px' },
        },
        textField: {
          inputProps: { placeholder: '' },
          style: {
            padding: '0px',
            margin: '0px',
          },
        },
      }}
    />
  );
};

/**
 * A styled version of the Material-UI DatePicker component.
 */
const DatePickerStyled = styled(DatePickerMUI)(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.default,
  borderRadius: '8px',
  '.MuiInputBase-input': {
    padding: '12px 3px 12px 3px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    padding: '0px',
  },
}));

import { Box, styled, Typography } from '@mui/material';
import React from 'react';

/**
 * Props for the TextWrapper component.
 */
export interface TextWrapperProps {
  /**
   * The content to be wrapped inside the component.
   */
  children: React.ReactNode;

  /**
   * The label text to display alongside the content.
   */
  label?: string;

  /**
   * The position of the label relative to the content.
   * Options: `'top'`, `'left'`, `'right'`, `'bottom'`.
   * Defaults to `'top'`.
   */
  labelPosition?: 'top' | 'left' | 'right' | 'bottom';

  /**
   * Custom styles for the main container of the wrapper.
   */
  sx?: object;

  /**
   * Custom styles for the label text.
   */
  labelSx?: object;
}

/**
 * A wrapper component that displays a label and child content with customizable label position and styles.
 *
 * @param {TextWrapperProps} props - The props for the TextWrapper component.
 * @returns {React.ReactElement} The rendered TextWrapper component.
 *
 * @example
 * <TextWrapper label="Name" labelPosition="left">
 *    <input type="text" />
 * </TextWrapper>
 */
export const TextWrapper: React.FC<TextWrapperProps> = ({
  label,
  labelPosition = 'top',
  children,
  sx = { padding: '20px' },
  labelSx = {
    fontWeight: 'bold',
    lineHeight: '5px',
    paddingLeft: '2px',
    fontSize: '14px',
  },
}) => {
  return (
    <TextContainer labelPosition={labelPosition} sx={sx}>
      {/* Render the label if provided */}
      {label && <Typography sx={labelSx}>{label}</Typography>}
      {/* Render the children */}
      <ContentContainer>{children}</ContentContainer>
    </TextContainer>
  );
};

/**
 * Styled container for the entire wrapper, managing layout and label positioning.
 */
const TextContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'labelPosition',
})<{ labelPosition: string }>(({ theme, labelPosition }) => ({
  display: 'flex',
  flexDirection: getFlexDirection(labelPosition), // Determine direction based on label position
  alignItems: labelPosition === 'left' || labelPosition === 'right' ? 'center' : undefined,
  gap: theme.spacing(1), // Add spacing between label and content
}));

/**
 * Helper function to determine flex direction based on label position.
 *
 * @param {string} labelPosition - The position of the label.
 * @returns {string} The corresponding flex direction.
 */
const getFlexDirection = (labelPosition: string) => {
  switch (labelPosition) {
    case 'top':
      return 'column';
    case 'bottom':
      return 'column-reverse';
    case 'left':
      return 'row';
    case 'right':
      return 'row-reverse';
    default:
      return 'column';
  }
};

/**
 * Styled container for wrapping the children content.
 */
const ContentContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center', // Align content vertically
});

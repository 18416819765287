import React, { useEffect, useState } from 'react';
import { BaseModal, BaseModalProps } from '../../components/modals/BaseModal';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { ColumnBox, RowBox } from '../../styles/commonStyles';
import { ProgressStep } from '../../components/common/ProgressStep';
import { CardLayout } from '../../components/layouts/CardLayout';
import { TextWrapper } from '../../components/common/TextWrapper';
import { Dropdown } from '../../components/common/Dropdown';
import { AccessTime, Add, CalendarMonth, EventRepeat, Person, Work } from '@mui/icons-material';
import useAPI from '../../hooks/useAPI';
import { Customer } from '../../models/Customer';
import { SearchSelect } from '../../components/common/SearchSelect';
import { TagField } from '../../components/common/TagField';
import { Order } from '../../models/Order';
import { generateRandomNumberWithDash } from '../../utils/Utils';
import { useModal } from '../../contexts/ModalContext';

export const OrderModal: React.FC<BaseModalProps> = ({ name, path, title }) => {
  // Use modal context
  const { open } = useModal();
  // Fetch customers
  const { data: customerData, callAPI: cutomerCallAPI } = useAPI<Customer[]>(
    `/customer/Företag?relations=${['contracts']}`
  );
  const [customers, setCustomers] = useState<string[]>([]);

  // Keep state for steps
  const [steps, setSteps] = useState<Map<string, boolean>>(
    new Map([
      ['Skapa order', false],
      ['Datum & Tider', false],
      ['Arbetsmoment', false],
      ['Attribut', false],
    ])
  );
  // Order Object
  const [order, setOrder] = useState<Order>({
    orderNumber: generateRandomNumberWithDash(),
    contract: undefined,
    customer: undefined,
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    frequency: '',
    duties: [],
    roles: [],
    languages: [],
    educations: [],
    totalValue: 0,
  });
  // Save order
  const { data, error, callAPI } = useAPI<Order>('/order', {
    method: 'POST',
    data: order,
  });

  const updateStep = (stepName: string, value: boolean) => {
    setSteps((prevSteps) => {
      const updatedSteps = new Map(prevSteps); // Copy the current map
      updatedSteps.set(stepName, value); // Update the specific step value
      return updatedSteps; // Return the updated map
    });
  };
  // Update steps
  useEffect(() => {
    updateStep('Skapa order', !!order.customer && !!order.contract);
    updateStep(
      'Datum & Tider',
      order.startDate.length > 0 &&
        order.endDate.length > 0 &&
        order.frequency.length > 0 &&
        order.startTime.length > 0 &&
        order.endTime.length > 0
    );
    updateStep('Arbetsmoment', order.duties.length > 0);
    updateStep(
      'Attribut',
      order.roles.length > 0 && order.languages.length > 0 && order.educations.length > 0
    );
  }, [order]);

  // handle on change
  const handleOnChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    // Get var name and value
    const { name, value } = e.target;

    // Update employee
    setOrder((prev) => ({
      ...prev,
      [name as string]: value,
    }));
  };
  // handle on tag change
  const handelOnTagChange = (tags: string[], name?: string) => {
    if (!name) return; // skip setting order
    setOrder((prev) => ({
      ...prev,
      [name]: tags,
    }));
  };

  // Handle Save form
  const handleSubmit = () => {
    callAPI(); //  save order
  };

  // On Fetch Data
  useEffect(() => {
    // set customer data
    if (customerData) {
      const names = customerData.map((obj) => obj.name);
      setCustomers(names);
    }
  }, [customerData]);

  // Handle saving
  useEffect(() => {
    if (data) {
      // Refresh to fetch newly registered employees
      window.location.reload();
    }
    if (error) {
      console.log(error);
    }
  }, [data, error]);

  return (
    <BaseModal name={name} path={path} title={title}>
      <RowBox>
        {/* Informaiton Side */}
        <ColumnBox sx={{ width: '30%' }}>
          <ProgressStep steps={steps} />
        </ColumnBox>
        {/* Content Side */}
        <ColumnBox sx={{ width: '70%' }}>
          <CardLayout
            title="Skapa order"
            index={1}
            description="Vilken kund och projekt ska personalen knytas till?"
          >
            <TextWrapper label="Välj Kund" labelPosition="top">
              <SearchSelect
                onFocus={cutomerCallAPI}
                name="customer"
                onChange={handleOnChange}
                options={customers}
                placeholder="Sök kunden"
                icon={<Person sx={{ color: 'gray', marginRight: '7px' }} />}
                iconAlign="left"
              />
              <IconButton
                onClick={() => {
                  open('CustomerModal');
                }}
              >
                <Add />
              </IconButton>
            </TextWrapper>
            <TextWrapper label="Välj Projekt" labelPosition="top">
              <SearchSelect
                name="contract"
                onChange={handleOnChange}
                options={[]}
                placeholder="Sök projekt"
                icon={<Work sx={{ color: 'gray', marginRight: '7px' }} />}
                iconAlign="left"
              />
              <IconButton
                onClick={() => {
                  open('ProjectModal');
                }}
              >
                <Add />
              </IconButton>
            </TextWrapper>
          </CardLayout>
          <CardLayout title="Datum & Tider" index={2} description="När ska order genomföras?">
            <RowBox sx={{ alignItems: 'center' }}>
              <TextWrapper label="Datum" labelPosition="left">
                <Dropdown
                  sx={{ width: '170px' }}
                  name="startDate"
                  onChange={handleOnChange}
                  title="Startdatum"
                  icon={<CalendarMonth sx={{ paddingLeft: '5px' }} />}
                  data={['2022-01-01', '2024-05-09']}
                />
              </TextWrapper>
              <Typography>-</Typography>
              <TextWrapper>
                <Dropdown
                  sx={{ width: '170px' }}
                  name="endDate"
                  onChange={handleOnChange}
                  title="Slutdatum"
                  icon={<CalendarMonth sx={{ paddingLeft: '5px' }} />}
                  data={['2025-05-01', '2027-05-01']}
                />
              </TextWrapper>
            </RowBox>
            <RowBox sx={{ alignItems: 'center' }}>
              <TextWrapper label="Starttid" labelPosition="left">
                <Dropdown
                  sx={{ width: '170px' }}
                  name="startTime"
                  onChange={handleOnChange}
                  title="00:00"
                  icon={<AccessTime sx={{ paddingLeft: '5px' }} />}
                  data={['06:00', '07:00']}
                />
              </TextWrapper>
              <Typography>-</Typography>
              <TextWrapper>
                <Dropdown
                  sx={{ width: '170px' }}
                  name="endTime"
                  onChange={handleOnChange}
                  title="00:00"
                  icon={<AccessTime sx={{ paddingLeft: '5px' }} />}
                  data={['16:00', '17:00']}
                />
              </TextWrapper>
            </RowBox>
            <TextWrapper label="Frekvenstyp" labelPosition="top">
              <Dropdown
                name="frequency"
                onChange={handleOnChange}
                title="Välj frekvens"
                icon={<EventRepeat sx={{ paddingLeft: '5px' }} />}
                data={['Veckovis', 'Månatlig']}
              />
            </TextWrapper>
          </CardLayout>
          <CardLayout title="Arbetsmoment" index={3} description="Vad ska göras, hur/när/var/vad">
            <TextWrapper label="Arbetsmoment" labelPosition="top">
              <TagField
                name="duties"
                title="Lägg till fler..."
                tags={order.duties}
                onChange={handelOnTagChange}
              />
            </TextWrapper>
          </CardLayout>
          <CardLayout title="Attribut" index={4} description="Speciella saker">
            <RowBox>
              <TextWrapper label="Roller" labelPosition="top">
                <TagField
                  name="roles"
                  title="Lägg till fler..."
                  tags={order.roles}
                  onChange={handelOnTagChange}
                />
              </TextWrapper>
              <TextWrapper label="Språk" labelPosition="top">
                <TagField
                  name="languages"
                  title="Lägg till fler..."
                  tags={order.languages}
                  onChange={handelOnTagChange}
                />
              </TextWrapper>
              <TextWrapper label="Utbildningar" labelPosition="top">
                <TagField
                  name="educations"
                  title="Lägg till fler..."
                  tags={order.educations}
                  onChange={handelOnTagChange}
                />
              </TextWrapper>
            </RowBox>
          </CardLayout>
          <Box sx={{ display: 'flex', flexFlow: 'row-reverse', padding: '15px' }}>
            <Button onClick={handleSubmit} variant="contained">
              Spara
            </Button>
          </Box>
        </ColumnBox>
      </RowBox>
    </BaseModal>
  );
};

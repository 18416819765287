import React, { useEffect, useState } from 'react';
import { BaseModal, BaseModalProps } from '../../components/modals/BaseModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAPI from '../../hooks/useAPI';
import { Contract } from '../../models/Contract';
import { useAlert } from '../../contexts/AlertContext';
import { Box, Button, Container } from '@mui/material';
import { CardLayout } from '../../components/layouts/CardLayout';
import { TextWrapper } from '../../components/common/TextWrapper';
import { TextField } from '../../components/common/TextField';
import { useModal } from '../../contexts/ModalContext';
import { RowBox } from '../../styles/commonStyles';
import { Badge, Email, Home, LocationCity, MarkunreadMailbox, Pin } from '@mui/icons-material';
import { CollapsibleSection } from '../../components/common/CollapsibleSection';

export const ContractActionModal: React.FC<BaseModalProps> = ({ path, name, title }) => {
  // Modal state
  const { modalState, close } = useModal();
  // Use navigation
  const navigate = useNavigate();
  // Contract object
  const [contract, setContract] = useState<Contract | null>(null);
  // Has offer expired
  const [isValid, setIsValid] = useState<boolean>(false);
  // Use Alert notification
  const { alert } = useAlert();
  // Use search params to get id from query
  const [params] = useSearchParams();
  // Get id from params
  const contractId = params.get('id');
  // Fetch contract by id from api
  const { data, callAPI } = useAPI<Contract>(`/contract/${contractId}?relations=${['customer']}`);
  // submit action to api
  const { data: response, callAPI: submitToAPI } = useAPI<Contract>('/contract/', {
    method: 'PUT',
  });
  // Poplate view when contract is loaded
  useEffect(() => {
    // set contract
    if (!modalState[name]) return; // skip setting when modal is closed

    if (data) {
      setContract(data);
      const validCheck = new Date(data.validationPeriod ?? '') > new Date();
      if (!validCheck) {
        alert('warning', 'Erbjudandet har löpt ut');
      }
      // set is valid status
      setIsValid(validCheck);
    } else {
      alert('error', 'No contract found with this id!');
    }
  }, [data]);
  // Request to fetch contract on component mount
  useEffect(() => {
    if (modalState[name]) {
      callAPI(); // fetch contract
    }
  }, [modalState]);

  // Handle onAccept
  const onAccept = () => {
    setStatus('Godkänd');
  };

  // Handle onDecline
  const onDecline = () => {
    setStatus('Avvisad');
  };

  // Set contract status
  const setStatus = (status: 'Godkänd' | 'Avvisad') => {
    const body = { ...contract, status };
    console.log(body);
    // Submit to API
    submitToAPI(body);
  };
  // Poplate view when contract is loaded
  useEffect(() => {
    // set contract
    if (!modalState[name]) return; // skip setting when modal is closed

    if (response) {
      alert('success', 'Contract accepted successfully!');
    } else {
      alert('error', 'Contract declined!');
    }
    // navigate back to offer and close modal
    close(name);
    navigate('/offert');
    window.location.reload();
  }, [response]);

  return (
    <BaseModal path={path} name={name} title={`${title} #${contractId}`}>
      <Container>
        <CardLayout
          title="Leverantören"
          description="Uppgifter om leverantören, juridiska företagsuppgifter"
          index={1}
        >
          <RowBox>
            <TextWrapper
              label="Företagsnamn"
              sx={{ padding: '20px', width: '100%' }}
              labelPosition="top"
            >
              <TextField
                icon={<Badge sx={{ color: 'grey', marginRight: '5px' }} />}
                iconAlign="left"
                readOnly={true}
                value="Dygnet Runt Städ i Skåne AB"
              />
            </TextWrapper>
            <TextWrapper
              label="Org nr."
              sx={{ padding: '20px', width: '100%' }}
              labelPosition="top"
            >
              <TextField
                icon={<Pin sx={{ color: 'grey', marginRight: '5px' }} />}
                iconAlign="left"
                readOnly={true}
                value="556368-7812"
              />
            </TextWrapper>
          </RowBox>
          <RowBox>
            <TextWrapper label="Adress" sx={{ padding: '20px', width: '50%' }} labelPosition="top">
              <TextField
                icon={<Home sx={{ color: 'grey', marginRight: '5px' }} />}
                iconAlign="left"
                readOnly={true}
                value="Regnvattengatan 5"
              />
            </TextWrapper>
            <RowBox sx={{ width: '50%' }}>
              <TextWrapper
                label="Post nr"
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <TextField
                  icon={<MarkunreadMailbox sx={{ color: 'grey', marginRight: '5px' }} />}
                  iconAlign="left"
                  readOnly={true}
                  value="21223"
                />
              </TextWrapper>
              <TextWrapper label="Ort" sx={{ padding: '20px', width: '100%' }} labelPosition="top">
                <TextField
                  icon={<LocationCity sx={{ color: 'grey', marginRight: '5px' }} />}
                  iconAlign="left"
                  readOnly={true}
                  value="Malmö"
                />
              </TextWrapper>
            </RowBox>
          </RowBox>
        </CardLayout>
        <CardLayout
          title="Beställaren"
          description="Uppgifter om beställaren, juridiska företagsuppgifter"
          index={2}
        >
          <RowBox>
            <TextWrapper
              label="Företagsnamn"
              sx={{ padding: '20px', width: '100%' }}
              labelPosition="top"
            >
              <TextField
                icon={<Badge sx={{ color: 'grey', marginRight: '5px' }} />}
                iconAlign="left"
                readOnly={true}
                value={contract?.customer?.name}
              />
            </TextWrapper>
            <TextWrapper
              label="Org nr."
              sx={{ padding: '20px', width: '100%' }}
              labelPosition="top"
            >
              <TextField
                icon={<Pin sx={{ color: 'grey', marginRight: '5px' }} />}
                iconAlign="left"
                readOnly={true}
                value={contract?.customer?.identityNumber}
              />
            </TextWrapper>
          </RowBox>
          <RowBox>
            <TextWrapper label="Adress" sx={{ padding: '20px', width: '50%' }} labelPosition="top">
              <TextField
                icon={<Home sx={{ color: 'grey', marginRight: '5px' }} />}
                iconAlign="left"
                readOnly={true}
                value={contract?.customer?.address}
              />
            </TextWrapper>
            <RowBox sx={{ width: '50%' }}>
              <TextWrapper
                label="Post nr"
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <TextField
                  icon={<MarkunreadMailbox sx={{ color: 'grey', marginRight: '5px' }} />}
                  iconAlign="left"
                  readOnly={true}
                  value={contract?.customer?.postNumber}
                />
              </TextWrapper>
              <TextWrapper label="Ort" sx={{ padding: '20px', width: '100%' }} labelPosition="top">
                <TextField
                  icon={<LocationCity sx={{ color: 'grey', marginRight: '5px' }} />}
                  iconAlign="left"
                  readOnly={true}
                  value={contract?.customer?.location}
                />
              </TextWrapper>
            </RowBox>
          </RowBox>
        </CardLayout>
        <CardLayout title="Projektet" description="Uppgifter om projektet" index={3}>
          <RowBox>
            <TextWrapper label="Adress" sx={{ padding: '20px', width: '50%' }} labelPosition="top">
              <TextField
                placeholder="Adress"
                icon={<Home sx={{ color: 'grey', marginRight: '5px' }} />}
                iconAlign="left"
                readOnly={true}
                value={contract?.address}
              />
            </TextWrapper>
            <RowBox sx={{ width: '50%' }}>
              <TextWrapper
                label="Post nr"
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <TextField
                  placeholder="Post nr"
                  icon={<MarkunreadMailbox sx={{ color: 'grey', marginRight: '5px' }} />}
                  iconAlign="left"
                  readOnly={true}
                  value={contract?.postNumber}
                />
              </TextWrapper>
              <TextWrapper label="Ort" sx={{ padding: '20px', width: '100%' }} labelPosition="top">
                <TextField
                  placeholder="Ort"
                  icon={<LocationCity sx={{ color: 'grey', marginRight: '5px' }} />}
                  iconAlign="left"
                  readOnly={true}
                  value={contract?.city}
                />
              </TextWrapper>
            </RowBox>
          </RowBox>
        </CardLayout>
        <CardLayout
          title="Fakturingsuppgifter"
          description="Ange en mailadress dit fakturorna skall gå."
          index={4}
        >
          <TextWrapper
            label="Mailadress"
            sx={{ padding: '20px', width: '100%' }}
            labelPosition="top"
          >
            <TextField
              icon={<Email sx={{ color: 'grey', marginRight: '5px' }} />}
              iconAlign="left"
              placeholder="Mailadress"
            />
          </TextWrapper>
        </CardLayout>
        <CardLayout title="Ordrar" description="Information om offererade ordrar" index={5}>
          <CollapsibleSection height="400px" title="Order 1">
            <RowBox sx={{ width: '100%' }}>
              <TextWrapper
                label="Uppdragstyp"
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <TextField
                  placeholder="Uppdragstyp"
                  icon={<MarkunreadMailbox sx={{ color: 'grey', marginRight: '5px' }} />}
                  iconAlign="left"
                  readOnly={true}
                  value={contract?.postNumber}
                />
              </TextWrapper>
              <TextWrapper
                label="Debiteringstyp"
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <TextField
                  placeholder="Debiteringstyp"
                  icon={<LocationCity sx={{ color: 'grey', marginRight: '5px' }} />}
                  iconAlign="left"
                  readOnly={true}
                  value={contract?.city}
                />
              </TextWrapper>
            </RowBox>
            <RowBox sx={{ width: '100%' }}>
              <TextWrapper
                label="Märkning"
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <TextField
                  placeholder="Märkning"
                  icon={<MarkunreadMailbox sx={{ color: 'grey', marginRight: '5px' }} />}
                  iconAlign="left"
                  readOnly={true}
                  value={contract?.postNumber}
                />
              </TextWrapper>
              <TextWrapper
                label="Startdatum"
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <TextField
                  placeholder="Startdatum"
                  icon={<LocationCity sx={{ color: 'grey', marginRight: '5px' }} />}
                  iconAlign="left"
                  readOnly={true}
                  value={contract?.city}
                />
              </TextWrapper>
            </RowBox>
          </CollapsibleSection>
          <CollapsibleSection title="Order 2">TEST</CollapsibleSection>
          <CollapsibleSection title="Order 3">TEST</CollapsibleSection>
        </CardLayout>
        <Box sx={{ display: 'flex', flexFlow: 'row-reverse', padding: '15px' }}>
          {isValid && (
            <>
              <Button onClick={onAccept} variant="contained">
                Acceptera
              </Button>
              <Button
                onClick={onDecline}
                sx={{ bgcolor: '#C1666B', marginRight: '10px' }}
                variant="contained"
              >
                Avvisad
              </Button>
            </>
          )}
        </Box>
      </Container>
    </BaseModal>
  );
};

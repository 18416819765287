import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Container, Typography, useTheme } from '@mui/material';
import React from 'react';

interface Props {
  error: Error;
}

export const ErrorPageAuth = ({ error }: Props) => {
  const theme = useTheme();
  const { logout } = useAuth0();
  console.log({ error });

  const handleLogin = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <Container
      sx={{ display: 'flex', flexDirection: 'column', height: '100svh', justifyContent: 'center' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          fontSize={50}
          fontWeight="bold"
          fontStyle="italic"
          color={theme.palette.customColors.color1}
        >
          Hoppsan nu var det något som gick fel
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <Typography>{error?.message}</Typography>

        <Typography>Prova logga in igen</Typography>

        <Button variant="contained" onClick={handleLogin}>
          Logga in
        </Button>
      </Box>
    </Container>
  );
};

const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || '';

export const providerConfig = {
  domain: domain,
  clientId: clientId,
  authorizationParams: {
    audience: audience,
    redirect_uri: window.location.origin,
  },
};

import { EmployeeForm } from './EmployeeForm';

export const initialEmployeeState: EmployeeForm = {
  employeeId: '',

  // Allmänna uppgifter
  personalIdentityNumber: '',
  firstName: '',
  lastName: '',

  // Kontaktuppgifter
  address: '',
  postCode: '',
  city: '',
  tel: '',
  email: '',

  // Närmst anhörig
  nextOfKin1FirstName: '',
  nextOfKin1LastName: '',
  nextOfKin1Phone: '',
  nextOfKin1Relation: '',
  nextOfKin2FirstName: '',
  nextOfKin2LastName: '',
  nextOfKin2Phone: '',
  nextOfKin2Relation: '',

  // Anställningsuppgifter
  employmentDate: null,
  endOfEmploymentDate: null,
  employmentForm: '',
  extent: '',
  startTime: '',
  endTime: '',
  salaryForm: '',
  personelType: '',

  // Lön
  monthlySalary: 0,
  addition: 0,

  // Attributer
  languages: [],
  utbildningar: [],
  allergier: [],
  körkort: [],
};

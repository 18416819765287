import { Button, Checkbox } from '@mui/material';
import React from 'react';
import PageLayout from '../../components/layouts/PageLayout';
import { SearchSelect } from '../../components/common/SearchSelect';
import { Column, TableWidget } from '../../components/widgets/TableWidget';
import { StatWidget } from '../../components/widgets/StatWidget';
import { Search } from '@mui/icons-material';
import { lineData, monthsData } from '../../utils/Utils';

interface InvoiceTable {
  checkBox: React.ReactNode;
  id: number;
  name: string;
  orgNumber: string;
  period: string;
  rut: string;
  sum: string;
}

const invoicesColumn: Column[] = [
  { header: 'Select', accessor: 'checkBox', align: 'left' },
  { header: 'Nr.', accessor: 'id', align: 'left' },
  { header: 'Kund', accessor: 'name', align: 'left' },
  { header: 'Org.Nr', accessor: 'orgNumber', align: 'left' },
  { header: 'Period', accessor: 'period', align: 'left' },
  { header: 'RUT', accessor: 'rut', align: 'right' },
  { header: 'Summa', accessor: 'sum', align: 'right' },
];

const invoiceData: InvoiceTable[] = [
  {
    checkBox: <Checkbox />,
    id: 1,
    name: 'Akea',
    orgNumber: '1213-1212',
    period: '2024/01/01 - 2024/05/05',
    rut: '120341',
    sum: '123,000 kr',
  },
  {
    checkBox: <Checkbox />,
    id: 1,
    name: 'IKEA',
    orgNumber: '1213-1212',
    period: '2024/01/01 - 2024/05/05',
    rut: '120341',
    sum: '123,000 kr',
  },
  {
    checkBox: <Checkbox />,
    id: 1,
    name: 'GESHDO',
    orgNumber: '1213-1212',
    period: '2024/01/01 - 2024/05/05',
    rut: '120341',
    sum: '123,000 kr',
  },
  {
    checkBox: <Checkbox />,
    id: 1,
    name: 'MAXI',
    orgNumber: '1213-1212',
    period: '2024/01/01 - 2024/05/05',
    rut: '120341',
    sum: '123,000 kr',
  },
];

const Invoice: React.FC = () => {
  // handle OnEdit
  const handleOnEdit = () => {};
  // Handle on Close offer
  const handleExport = () => {};
  return (
    <PageLayout
      title="Fakturaunderlag"
      onEdit={handleOnEdit}
      extras={[
        <Button key="export" variant="contained" onClick={handleExport}>
          Exportera
        </Button>,
        <SearchSelect
          key="search"
          inputStyle={{ background: 'white' }}
          placeholder="Sök"
          icon={<Search />}
          iconAlign="right"
        />,
      ]}
    >
      <StatWidget title="Fakturerat" variant="bar" size={12} xAxis={monthsData} data={lineData} />
      <TableWidget title="Fakturor Lista" size={12} data={invoiceData} columns={invoicesColumn} />
    </PageLayout>
  );
};

export default Invoice;

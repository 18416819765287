import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { ColumnBox, RowBox } from '../../styles/commonStyles';
import { styled } from '@mui/material/styles';

/**
 * Props for the CardLayout component.
 */
interface CardProps {
  /**
   * Optional index number to display on the card.
   */
  index?: number;

  /**
   * Title text for the card.
   */
  title: string;

  /**
   * Optional description text for the card.
   */
  description?: string;

  /**
   * Optional side component, such as an icon or button, to display on the right side of the card.
   */
  sideComponent?: React.ReactNode;

  /**
   * The content to display in the main body of the card.
   */
  children: React.ReactNode;
}

/**
 * A card layout component that displays a title, optional description, and a content section.
 * It also supports an optional index and side component for additional functionality.
 *
 * @param {CardProps} props - The props for the CardLayout component.
 * @returns {React.ReactElement} The rendered CardLayout component.
 *
 * @example
 * <CardLayout
 *    index={1}
 *    title="Card Title"
 *    description="This is a description."
 *    sideComponent={<Button>Click Me</Button>}
 * >
 *    <Typography>Content goes here</Typography>
 * </CardLayout>
 */
export const CardLayout: React.FC<CardProps> = ({
  index,
  title,
  description,
  children,
  sideComponent,
}) => {
  return (
    <CardContainer>
      {/* Title Section */}
      <RowBox sx={{ width: '100%', justifyContent: 'space-between' }}>
        <RowBox sx={{ padding: '15px', alignItems: 'center' }}>
          {/* Optional index display */}
          <IndexContainer>
            <Typography fontSize="14px">{index}</Typography>
          </IndexContainer>
          {/* Title and description */}
          <ColumnBox sx={{ paddingLeft: '15px' }}>
            <Typography fontWeight="bold" fontSize="15px" lineHeight="15px">
              {title}
            </Typography>
            <Typography fontStyle="italic" fontSize="13px" color="gray">
              {description}
            </Typography>
          </ColumnBox>
        </RowBox>
        {/* Optional side component */}
        <RowBox sx={{ alignItems: 'center', paddingRight: '10px' }}>{sideComponent}</RowBox>
      </RowBox>
      <Divider />
      {/* Content Section */}
      <ColumnBox sx={{ padding: '15px' }}>{children}</ColumnBox>
    </CardContainer>
  );
};

/**
 * Styled container for the index circle.
 */
const IndexContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.customColors.color2,
  padding: '10px',
  width: '30px',
  height: '30px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
}));

/**
 * Styled container for the entire card layout.
 */
const CardContainer = styled(Box)(() => ({
  background: 'white',
  borderRadius: '10px',
  margin: '0 15px 15px 15px',
}));

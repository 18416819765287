import React, { useEffect, useState } from 'react';
import { BaseModal, BaseModalProps } from '../../components/modals/BaseModal';
import { Box, Button, IconButton } from '@mui/material';
import { ColumnBox, RowBox } from '../../styles/commonStyles';
import { ProgressStep } from '../../components/common/ProgressStep';
import { CardLayout } from '../../components/layouts/CardLayout';
import { TextWrapper } from '../../components/common/TextWrapper';
import { SearchSelect } from '../../components/common/SearchSelect';
import {
  Add,
  AddLink,
  Home,
  LocationOn,
  MarkunreadMailbox,
  Notes,
  Person,
  Work,
} from '@mui/icons-material';
import { TextField } from '../../components/common/TextField';
import useAPI from '../../hooks/useAPI';
import { Contract } from '../../models/Contract';
import { Customer } from '../../models/Customer';
import { useModal } from '../../contexts/ModalContext';
import { useAlert } from '../../contexts/AlertContext';
import { mergeFieldsIntoTarget } from '../../utils/Utils';
import { FieldConfig, MultiLineForm } from '../../components/common/MultiLineForm';
import { useFilePicker } from 'use-file-picker';
import { DatePicker } from '../../components/common/DatePicker';
import { useNavigate } from 'react-router-dom';

export const ContractModal: React.FC<BaseModalProps> = ({ path, title, name }) => {
  // Use Navigate
  const navigate = useNavigate();
  // Check if its Project Modal (Creation Project Mode)
  const isProject = name == 'ProjectModal';
  // Use modal context
  const { close, open } = useModal();
  // Use alert contex
  const { alert } = useAlert();
  // Use FilePicker
  const { openFilePicker, filesContent } = useFilePicker({});
  // API call to fetch customers
  const { data, callAPI } = useAPI<Customer[]>(`/customer/?relations=${['orders', 'contracts']}`);
  const [contract, setContract] = useState<Contract>({
    id: undefined,
    status: isProject ? 'Godkänd' : 'Utkast',
    customer: undefined,
    address: '',
    city: '',
    postNumber: '',
    validationPeriod: new Date().toISOString(),
    orders: undefined,
    products: undefined,
    contacts: undefined,
    note: '',
    createdOn: new Date().toDateString(),
  });
  // Send offer
  const {
    data: accepted,
    error: failed,
    callAPI: sendOfferAPI,
    isLoading: isSubmiting,
  } = useAPI<string>('/contract/', { method: 'POST', data: contract });
  // MultiForm Contact Fields
  const contactsField: FieldConfig[] = [
    {
      key: 'contacts', // must be the same as object property also name in the Multiline Form component
      placeholder: 'Mottagare',
      type: 'select',
      width: '100%',
      getOptionLabel: (o) => o.name ?? '',
      defaultValue: [
        {
          name: 'Alex Ivarsson',
          role: 'Granskare',
        },
        {
          name: 'Morgan Ivarsson',
          role: 'Signerare',
        },
      ],
      dependentFields: { role: 'role' },
    },
    {
      key: 'role',
      placeholder: 'Roll',
      type: 'select',
      defaultValue: ['Granskare', 'Signerare'],
    },
  ];
  // MultiForm Contact Fields
  const ordersField: FieldConfig[] = [
    {
      key: 'orders',
      placeholder: 'Order',
      type: 'select',
      getOptionLabel: (o) => o.orderNumber ?? '',
      width: '100%',
      defaultValue: contract.customer?.orders?.filter((order) => order.contract === undefined),
    },
  ];
  // MultiForm Contact Fields
  const productsField: FieldConfig[] = [
    {
      key: 'products',
      placeholder: 'Produkt',
      type: 'select',
      getOptionLabel: (o) => o.name ?? '',
      width: '100%',
      defaultValue: [
        {
          name: 'Toalettpapper',
          price: '120',
          discount: '0',
          total: '0',
        },
        {
          name: 'Handuk',
          price: '80',
          discount: '0',
          total: '',
        },
      ],
      dependentFields: { price: 'price', discount: 'discount', total: 'total' },
    },
    {
      key: 'price',
      placeholder: 'Pris (kr)',
      type: 'text',
      readOnly: true,
    },
    {
      key: 'discount',
      placeholder: 'Rabatt %',
      type: 'text',
    },
    {
      key: 'total',
      placeholder: 'Totalt (kr)',
      type: 'text',
      readOnly: true,
      deriveValue: (data) => {
        const price = parseFloat(data.price || '0');
        const discount = parseFloat(data.discount || '0');
        return (price - price * (discount / 100)).toFixed(2) + ' kr';
      },
    },
  ];
  // Step tracking
  const [steps, setSteps] = useState<Map<string, boolean>>(
    new Map([
      ['Allmänna uppgifter', false],
      ['Mottagare', false],
      ['Koppla order', false],
      ['Koppla produkter', false],
      ['Övriga uppgifter', false],
    ])
  );

  // handle on change
  const handleOnChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    // Get var name and value
    const { name, value } = e.target;
    // Handle on project selected
    if (name === 'project') {
      if (value) {
        // When selected a project populate contract information
        const _contract = value as Contract;
        setContract((prev) => ({
          ...prev,
          ..._contract,
        }));
      } else {
        setContract((prev) => ({
          ...prev,
          id: undefined,
          address: '',
          city: '',
          postNumber: '',
        }));
      }
      return; // Skip adding contract to contract or project to contract
    }
    // Update Contract
    setContract((prev) => ({
      ...prev,
      [name as string]: value,
    }));
  };

  const handleMultiLineChange = (name: string, updatedData: Record<string, string>[]) => {
    // Temporary array
    const tempArray: unknown[] = [];
    updatedData.map((item) => {
      // Check if its multi colum form
      if (Object.keys(item).length > 1) {
        const mergetObject = mergeFieldsIntoTarget(item, name);
        if (mergetObject) tempArray.push(mergetObject);
      } else {
        // Single colum form
        if (typeof item[name] === 'object' && item[name] !== null) tempArray.push(item[name]);
      }
    });
    // Update Contract Object
    setContract((prev) => ({
      ...prev,
      [name as string]: tempArray,
    }));
  };

  // Update Step state
  const updateStep = (stepName: string, value: boolean) => {
    setSteps((prevSteps) => {
      const updatedSteps = new Map(prevSteps);
      updatedSteps.set(stepName, value);
      return updatedSteps;
    });
  };

  // Update steps
  useEffect(() => {
    updateStep(
      'Allmänna uppgifter',
      !!contract.customer && !!contract.address && !!contract.postNumber && !!contract.city
    );
    updateStep('Mottagare', Array.isArray(contract.contacts) && contract.contacts.length > 0);
    updateStep('Koppla order', Array.isArray(contract.orders) && contract.orders.length > 0);
    updateStep(
      'Koppla produkter',
      Array.isArray(contract.products) && contract.products.length > 0
    );
  }, [contract]);

  // Handle submit status
  useEffect(() => {
    if (accepted) {
      alert('success', 'Offer has been send successfully!');
      close('OfferModal');
      navigate('/offert');
      window.location.reload();
    }
    if (failed) {
      alert('error', `Error: ${failed}`);
    }
  }, [failed, accepted, isSubmiting]);
  // Handle Save form
  const handleSubmit = (status: 'Skickad' | 'Utkast') => {
    sendOfferAPI({ ...contract, status: status });
  };
  return (
    <BaseModal name={name} path={path} title={title}>
      <RowBox>
        {/* Informaiton Side */}
        <ColumnBox sx={{ width: '30%' }}>
          <ProgressStep steps={steps} />
        </ColumnBox>
        {/* Content Side */}
        <ColumnBox sx={{ width: '70%' }}>
          {/* Form 1 */}
          <CardLayout title="Allmänna uppgifter" index={1}>
            <RowBox sx={{ alignItems: 'center' }}>
              <TextWrapper
                label="Välj kund"
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <SearchSelect
                  name="customer"
                  onFocus={callAPI}
                  onChange={handleOnChange}
                  options={data || []}
                  getOptionLabel={(o) => `${o.identityNumber} | ${o.name}`}
                  icon={<Person sx={{ color: 'gray', marginRight: '7px' }} />}
                  iconAlign="left"
                  placeholder="Sök"
                />
                <IconButton
                  onClick={() => {
                    open('CustomerModal');
                  }}
                >
                  <Add />
                </IconButton>
              </TextWrapper>
            </RowBox>
            {!isProject && (
              <RowBox sx={{ alignItems: 'center' }}>
                <TextWrapper
                  label="Välj projekt"
                  sx={{ padding: '20px', width: '100%' }}
                  labelPosition="top"
                >
                  <SearchSelect
                    getOptionLabel={(o) => `${o.address}`}
                    name="project"
                    options={contract.customer?.contracts}
                    onChange={handleOnChange}
                    icon={<Work sx={{ color: 'gray', marginRight: '7px' }} />}
                    iconAlign="left"
                    placeholder="Sök"
                  />
                </TextWrapper>
              </RowBox>
            )}
            <TextWrapper label="Adress" labelPosition="top">
              <TextField
                name="address"
                readOnly={!!contract.id}
                onChange={handleOnChange}
                value={contract.address}
                placeholder="Adress"
                icon={<Home sx={{ color: 'gray', marginRight: '5px' }} />}
                iconAlign="left"
              />
            </TextWrapper>
            <RowBox sx={{ alignItems: 'center' }}>
              <TextWrapper
                label="Post Nr"
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <TextField
                  name="postNumber"
                  readOnly={!!contract.id}
                  onChange={handleOnChange}
                  value={contract.postNumber}
                  placeholder="Post Nr"
                  icon={<MarkunreadMailbox sx={{ color: 'gray', marginRight: '5px' }} />}
                  iconAlign="left"
                />
              </TextWrapper>
              <TextWrapper label="Ort" sx={{ padding: '20px', width: '100%' }} labelPosition="top">
                <TextField
                  name="city"
                  readOnly={!!contract.id}
                  onChange={handleOnChange}
                  value={contract.city}
                  placeholder="Ort"
                  icon={<LocationOn sx={{ color: 'gray', marginRight: '5px' }} />}
                  iconAlign="left"
                />
              </TextWrapper>
            </RowBox>
          </CardLayout>
          {/* Form 2 */}
          <CardLayout
            title="Mottagare"
            index={2}
            sideComponent={
              <IconButton
                onClick={() => {
                  open('OrderModal');
                }}
              >
                <Add />
              </IconButton>
            }
          >
            <TextWrapper label="Välj/skapa mottagare" labelPosition="top">
              <MultiLineForm
                name="contacts"
                onChange={handleMultiLineChange}
                fields={contactsField}
              />
            </TextWrapper>
          </CardLayout>
          {/* Form 3 */}
          <CardLayout
            title="Koppla order"
            index={3}
            sideComponent={
              <IconButton
                onClick={() => {
                  open('OrderModal');
                }}
              >
                <Add />
              </IconButton>
            }
          >
            <TextWrapper label="Välj/skapa order" labelPosition="top">
              <MultiLineForm name="orders" onChange={handleMultiLineChange} fields={ordersField} />
            </TextWrapper>
          </CardLayout>
          {/* Form 4 */}
          <CardLayout title="Koppla produkter" index={4}>
            <TextWrapper label="Välj produkter" labelPosition="top">
              <MultiLineForm
                name="products"
                onChange={handleMultiLineChange}
                fields={productsField}
              />
            </TextWrapper>
          </CardLayout>
          {/* Form 5 */}
          <CardLayout title="Övriga uppgifter" index={5}>
            <TextWrapper label="Giltlighetstid" labelPosition="top">
              <RowBox sx={{ width: '50%' }}>
                <DatePicker name="validationPeriod" onChange={handleOnChange} />
              </RowBox>
            </TextWrapper>
            <TextWrapper label="Skapa anteckningar till projektet" labelPosition="top">
              <TextField
                multiline={true}
                name="note"
                onChange={handleOnChange}
                placeholder="Anteckningar"
                icon={<Notes sx={{ color: 'gray', marginRight: '5px' }} />}
                iconAlign="left"
              />
            </TextWrapper>
            <TextWrapper label="Koppla bilagor" labelPosition="top">
              <TextField
                value={filesContent.map((file) => file.name)}
                onChange={handleOnChange}
                icon={<AddLink sx={{ color: 'gray', marginRight: '7px' }} />}
                iconAlign="left"
                placeholder="Koppla Bilagor"
              />
              <IconButton
                onClick={() => {
                  openFilePicker();
                }}
              >
                <Add />
              </IconButton>
            </TextWrapper>
          </CardLayout>
          <Box sx={{ display: 'flex', flexFlow: 'row-reverse', padding: '15px' }}>
            <Button
              onClick={() => {
                handleSubmit('Skickad');
              }}
              sx={{ background: '#345995' }}
              variant="contained"
            >
              Spara & skicka
            </Button>
            <Button
              onClick={() => {
                handleSubmit('Utkast');
              }}
              sx={{ marginRight: '10px' }}
              variant="contained"
            >
              Spara
            </Button>
          </Box>
        </ColumnBox>
      </RowBox>
    </BaseModal>
  );
};

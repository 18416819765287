import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { theme } from '../../configs/theme';

interface Props {
  heading?: string;
  ingress?: string;
}

export const FullPageSpinner: React.FC<Props> = (props) => {
  return (
    <Box
      flexDirection={'column'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        height: '100svh',
        width: '100svw',
      }}
    >
      <CircularProgress sx={{ color: theme.palette.customColors.color1 }} size="5rem" />
      {props.heading && (
        <Typography
          padding="50px 0 0 0"
          fontSize={20}
          fontWeight="bold"
          color={theme.palette.customColors.color1}
        >
          {props.heading}
        </Typography>
      )}
      {props.ingress && (
        <Typography fontSize={15} fontWeight="bold" color={theme.palette.customColors.color1}>
          {props.ingress}
        </Typography>
      )}
    </Box>
  );
};

import { MenuItem, Select, Typography, styled, Box } from '@mui/material';
import React from 'react';

/**
 * Props for the Dropdown component.
 */
interface DropdownProps {
  /**
   * Title to display as the placeholder when no value is selected.
   */
  title?: string;

  /**
   * Icon component to display alongside the dropdown.
   */
  icon?: React.ReactNode;

  /**
   * Array of data items to populate the dropdown options.
   */
  data?: any[];

  /**
   * Name of the dropdown field, useful for form handling.
   */
  name?: string;

  /**
   * Callback function triggered when an item is selected.
   * @param {any} value - The selected value.
   */
  onChange?: (event: any) => void;

  /**
   * Custom styles for the root container of the dropdown.
   */
  sx?: object;

  /**
   * The current selected value. If omitted, we'll fallback to ''.
   */
  value?: string;
}

/**
 * A dropdown component with optional icon and customizable styles.
 *
 * @param {DropdownProps} props - The props for the Dropdown component.
 * @returns {React.ReactElement} The rendered Dropdown component.
 *
 * @example
 * <Dropdown
 *    title="Select an option"
 *    icon={<FormatListBulleted />}
 *    data={['Option 1', 'Option 2', 'Option 3']}
 *    onChange={(value) => console.log(value)}
 *    sx={{ margin: '10px' }}
 * />
 */
export const Dropdown: React.FC<DropdownProps> = ({
  title,
  icon,
  data,
  onChange,
  sx,
  name,
  value,
}) => {
  return (
    <SelectContainer sx={sx}>
      {icon}
      <SelectStyled
        onChange={onChange}
        displayEmpty
        name={name}
        value={value ?? ''} // fallback to empty string if not provided
        renderValue={(value: any) => {
          return value ? value : <Typography>{title}</Typography>;
        }}
      >
        {data?.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </SelectStyled>
    </SelectContainer>
  );
};

/**
 * Styled container for the Dropdown, with icon and dropdown field alignment.
 */
const SelectContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  width: '100%',
  borderRadius: '8px',
  padding: '10px',
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
}));

/**
 * Styled version of the Material-UI Select component for consistent design.
 */
const SelectStyled = styled(Select)(() => ({
  width: '100%',
  height: '30px',
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

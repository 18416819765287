import { Tune } from '@mui/icons-material';
import { Box, Grid2, Container, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react';

/**
 * Props for the PageLayout component.
 */
interface LayoutProps {
  /**
   * The main title of the page.
   */
  title: string;

  /**
   * Optional additional components or elements to display in the header (e.g., buttons, icons).
   */
  extras?: React.ReactNode[];

  /**
   * The main content to display in the layout.
   */
  children?: React.ReactNode;

  /**
   * Callback function triggered when the edit icon (Tune button) is clicked.
   */
  onEdit?: () => void;
}

/**
 * A layout component for pages, providing a title, optional header components, and a content area.
 *
 * @param {LayoutProps} props - The props for the PageLayout component.
 * @returns {React.ReactElement} The rendered PageLayout component.
 *
 * @example
 * <PageLayout
 *    title="Dashboard"
 *    onEdit={() => console.log('Edit clicked')}
 *    extras={[<Button key="1">Extra Button</Button>]}
 * >
 *    <Typography>Page content goes here</Typography>
 * </PageLayout>
 */
const PageLayout: React.FC<LayoutProps> = ({ title, children, extras, onEdit }) => {
  const theme = useTheme();

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* Header Section */}
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {/* Page Title */}
        <Typography
          fontSize={50}
          fontWeight="bold"
          fontStyle="italic"
          color={theme.palette.customColors.color1}
        >
          {title}
        </Typography>

        {/* Extra Components and Edit Button */}
        <Box sx={{ display: 'flex', width: '100%', flexFlow: 'row-reverse' }}>
          {/* Edit Button */}
          <IconButton
            onClick={onEdit}
            sx={{
              border: 'none',
              margin: 0,
              height: 45,
              width: 50,
              borderRadius: '8px',
              ':hover': { bgcolor: theme.palette.customColors.color1 },
              color: 'white',
              bgcolor: theme.palette.customColors.color1,
            }}
          >
            <Tune />
          </IconButton>

          {/* Render Additional Components */}
          {extras?.map((item, index) => (
            <Box key={index} sx={{ marginRight: '10px' }}>
              {item}
            </Box>
          ))}
        </Box>
      </Box>

      {/* Main Content Section */}
      <Grid2 sx={{ paddingTop: '30px' }} container spacing={2}>
        {children}
      </Grid2>
    </Container>
  );
};

export default PageLayout;

import { Box, Button, styled } from '@mui/material';
import React, { useState } from 'react';

interface CollapsilbeProps {
  title: string;
  height?: string; // height in pixels
  children?: React.ReactNode;
}

export const CollapsibleSection: React.FC<CollapsilbeProps> = ({
  title,
  children,
  height = '200px',
}) => {
  const [expand, setExpand] = useState<boolean>(false);

  const handleOnClick = () => {
    setExpand(!expand);
  };

  return (
    <Container height={height} expand={expand}>
      {/* Header */}
      <Header onClick={handleOnClick}>{title}</Header>
      {/* Content */}
      <Content>{children}</Content>
    </Container>
  );
};

// Styled components
const Content = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'column',
  padding: '5px',
}));

const Container = styled(Box)<{ expand: boolean; height: string }>(({ expand, height }) => ({
  margin: '10px',
  display: 'flex',
  flexFlow: 'column',
  height: expand ? height : '50px',
  transition: 'height ease-out 0.3s',
  boxShadow: 'inset 0px 0px 5px rgba(0,0,0,0.5)',
  borderRadius: '8px',
  overflow: 'hidden',
}));

const Header = styled(Button)(({ theme }) => ({
  background: theme.palette.background.default,
  display: 'flex',
  flexFlow: 'column',
  minHeight: '50px',
  color: 'black',
  borderRadius: '8px',
}));

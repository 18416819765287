import React, { useEffect, useState } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { TextField } from './TextField';
import { SearchSelect } from './SearchSelect';

/**
 * Configuration for an individual form field.
 */
export interface FieldConfig {
  /**
   * The key for the field, used as both the object accessor and the field's name.
   */
  key: string;

  /**
   * The type of the field, either 'text' or 'select'.
   */
  type: 'text' | 'select';

  /**
   * If true, the field is read-only.
   */
  readOnly?: boolean;

  /**
   * Placeholder text for the field.
   */
  placeholder?: string;

  /**
   * Default value for the field.
   */
  defaultValue?: unknown[];

  /**
   * Function to derive the label for select options.
   * @param {any} o - The current option.
   * @returns {any} The label for the option.
   */
  getOptionLabel?: (o: any) => any;

  /**
   * Custom width for the field.
   */
  width?: string;

  /**
   * Function to calculate derived values for the field.
   * @param {Record<string, string>} rowData - The current row data.
   * @returns {string} The derived value.
   */
  deriveValue?: (rowData: Record<string, string>) => string;

  /**
   * Fields to update based on selected value, mapping field keys to object properties.
   */
  dependentFields?: Record<string, string>;
}

/**
 * Props for the MultiLineForm component.
 */
interface MultiLineFormProps {
  /**
   * An array of field configurations.
   */
  fields?: FieldConfig[];

  /**
   * Initial data for the form's rows.
   */
  initialData?: Record<string, string>[];

  /**
   * Callback function to notify the parent of changes.
   * @param {string} name - The name of the form.
   * @param {Record<string, string>[]} updatedData - The updated row data.
   */
  onChange?: (name: string, updatedData: Record<string, string>[]) => void;

  /**
   * The name of the form, used for identification.
   */
  name: string;
}

/**
 * A dynamic multi-line form component that supports text and select fields.
 *
 * @param {MultiLineFormProps} props - The props for the MultiLineForm component.
 * @returns {React.ReactElement} The rendered MultiLineForm component.
 *
 * @example
 * <MultiLineForm
 *    fields={[
 *        { key: 'name', type: 'text', placeholder: 'Enter name' },
 *        { key: 'age', type: 'text', placeholder: 'Enter age' },
 *        { key: 'country', type: 'select', defaultValue: ['USA', 'Canada'], placeholder: 'Select country' }
 *    ]}
 *    initialData={[{ name: 'John', age: '30', country: 'USA' }]}
 *    onChange={(name, data) => console.log(name, data)}
 *    name="exampleForm"
 * />
 */
export const MultiLineForm: React.FC<MultiLineFormProps> = ({
  fields = [],
  initialData = [],
  onChange,
  name,
}) => {
  const [rows, setRows] = useState<{ id: number; data: Record<string, string> }[]>(
    initialData.map((data, index) => ({ id: index, data })) || [
      {
        id: Date.now(),
        data: Object.fromEntries(fields.map((field) => [field.key, ''])),
      },
    ]
  );

  /**
   * Adds a new row to the form.
   */
  const addRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      {
        id: Date.now(),
        data: Object.fromEntries(fields.map((field) => [field.key, ''])),
      },
    ]);
  };

  /**
   * Removes a row from the form by its ID.
   *
   * @param {number} id - The ID of the row to remove.
   */
  const removeRow = (id: number) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  /**
   * Handles changes to a specific field in a row.
   *
   * @param {number} rowId - The ID of the row.
   * @param {string} fieldKey - The key of the field.
   * @param {any} fieldValue - The new value for the field.
   * @param {Record<string, any>} [selectedObject] - Optional object for updating dependent fields.
   */
  const handleFieldChange = (
    rowId: number,
    fieldKey: string,
    fieldValue: any,
    selectedObject?: Record<string, any>
  ) => {
    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === rowId) {
          const updatedData = { ...row.data, [fieldKey]: fieldValue };

          const fieldConfig = fields.find((field) => field.key === fieldKey);
          if (fieldConfig?.dependentFields && selectedObject) {
            Object.entries(fieldConfig.dependentFields).forEach(([dependentKey, objectKey]) => {
              updatedData[dependentKey] = selectedObject[objectKey] || '';
            });
          }

          fields.forEach((field) => {
            if (field.deriveValue) {
              updatedData[field.key] = field.deriveValue(updatedData);
            }
          });

          return { ...row, data: updatedData };
        }
        return row;
      })
    );
  };

  useEffect(() => {
    addRow();
  }, []);

  useEffect(() => {
    if (onChange) {
      const updatedData = rows.map((row) => row.data);
      onChange(name, updatedData);
    }
  }, [rows]);

  return (
    <FormContainer>
      {rows.map((row) => (
        <FormRow key={row.id}>
          {fields.map((field) => {
            switch (field.type) {
              case 'select':
                return (
                  <SearchSelect
                    key={field.key}
                    options={field.defaultValue}
                    sx={{ padding: '0px 5px', width: field.width }}
                    getOptionLabel={field.getOptionLabel}
                    placeholder={field.placeholder}
                    value={row.data[field.key]}
                    onChange={(event: any) =>
                      handleFieldChange(row.id, field.key, event.target.value, event.target.value)
                    }
                  />
                );
              case 'text':
                return (
                  <TextField
                    key={field.key}
                    sx={{ padding: '0px 5px', width: field.width }}
                    placeholder={field.placeholder}
                    value={row.data[field.key] || ''}
                    readOnly={field.readOnly}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleFieldChange(row.id, field.key, event.target.value)
                    }
                  />
                );
              default:
                return null;
            }
          })}
          <IconButton onClick={() => removeRow(row.id)}>
            <Remove />
          </IconButton>
        </FormRow>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton onClick={addRow}>
          <Add />
        </IconButton>
      </Box>
    </FormContainer>
  );
};

// Styled components
const FormRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '5px 0',
}));

const FormContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

import { Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import PageLayout from '../../components/layouts/PageLayout';
import { SearchSelect } from '../../components/common/SearchSelect';
import { Column, TableWidget } from '../../components/widgets/TableWidget';
import { Search } from '@mui/icons-material';
import useAPI from '../../hooks/useAPI';
import { Employee as EmployeeEntity } from '../../models/Employee';
import { useModal } from '../../contexts/ModalContext';
import { getNestedValue } from '../contracts/utils';
import { SortEmployeeTable } from './types';

const personnelColumn: Column[] = [
  { header: 'Nr.', accessor: 'id', align: 'left' },
  {
    header: 'Namn',
    accessor: 'firstName',
    align: 'left',
    render: (row: EmployeeEntity) => `${row.firstName} ${row.lastName}`,
  },
  { header: 'Anställningsdatum', accessor: 'employmentDate', align: 'center' },
  { header: 'Starttid', accessor: 'startTime', align: 'center' },
  { header: 'Sluttid', accessor: 'endTime', align: 'center' },
  { header: 'Anställningsform', accessor: 'employmentForm', align: 'center' },
  {
    header: 'Omfattning',
    accessor: 'extent',
    align: 'center',
    render: (row: EmployeeEntity) => `${row.extent || 0}%`,
  },
];

export const Employee: React.FC = () => {
  // Grab the `open` function from our modal context
  const { open } = useModal();

  // State for employees
  const [personnelData, setPersonnelData] = useState<EmployeeEntity[]>([]);
  // State for sorting
  const [sortConfig, setSortConfig] = useState<SortEmployeeTable>();

  // Use our custom API hook to get employees
  const { data, callAPI } = useAPI<EmployeeEntity[]>('/employee');

  // Fetch employees on mount
  const refreshData = async () => {
    await callAPI();
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line
  }, []);

  // Once data is fetched, store it
  useEffect(() => {
    if (data) {
      setPersonnelData(data);
    }
  }, [data]);

  // Sort the data if sortConfig is set
  const sortedData = useMemo(() => {
    if (!sortConfig) return personnelData;

    const { key, direction } = sortConfig;
    return [...personnelData].sort((a, b) => {
      const aValue = getNestedValue(a, key);
      const bValue = getNestedValue(b, key);

      // Handle string compare
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
      // Handle nullish
      if (aValue == null && bValue == null) return 0;
      if (aValue == null) return direction === 'asc' ? 1 : -1;
      if (bValue == null) return direction === 'asc' ? -1 : 1;

      // Handle numeric/date compare
      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [personnelData, sortConfig]);

  // Called when user clicks a column header
  const handleSort = (key: string) => {
    setSortConfig((prev) => {
      if (prev?.key === key) {
        return {
          key,
          direction: prev.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      return { key, direction: 'asc' };
    });
  };

  // "Create a new employee" -> open modal in CREATE mode
  const handleCreatePersonnel = () => {
    open('EmployeeModal', {
      mode: 'create',
      refreshData,
    });
  };

  // Clicking on a row -> open modal in EDIT mode, passing the row's ID
  const handleRowClick = (row: EmployeeEntity) => {
    open('EmployeeModal', {
      mode: 'edit',
      employeeId: row.id,
      employeeName: `${row.firstName} ${row.lastName}`,
      refreshData,
    });
    console.log('Clicked Row:', row);
  };

  return (
    <PageLayout
      title="Medarbetare"
      onEdit={() => {}}
      extras={[
        <Button key="create-personnel" variant="contained" onClick={handleCreatePersonnel}>
          Skapa
        </Button>,
        <SearchSelect
          key="search-select"
          inputStyle={{ background: 'white' }}
          placeholder="Sök"
          icon={<Search />}
          iconAlign="right"
        />,
      ]}
    >
      <TableWidget
        title="Medarbetare Lista"
        size={12}
        data={sortedData}
        columns={personnelColumn}
        onSort={handleSort}
        sortConfig={sortConfig}
        onRowClick={handleRowClick}
      />
    </PageLayout>
  );
};

import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { BaseModal, BaseModalProps } from '../../../components/modals/BaseModal';
import { ColumnBox, RowBox } from '../../../styles/commonStyles';
import { ProgressStep } from '../../../components/common/ProgressStep';

import { useModal } from '../../../contexts/ModalContext';
import useAPI from '../../../hooks/useAPI';
import { useFilePicker } from 'use-file-picker';

import { EmployeeForm } from '../../../models/EmployeeForm';
import { initialEmployeeState } from '../../../models/InitialEmployeeState';

import { EmployeeGeneralSection } from './EmployeeGeneralSection';
import { EmployeeContactSection } from './EmployeeContactSection';
import { EmployeeKinSection } from './EmployeeKinSection';
import { EmployeeEmploymentSection } from './EmployeeEmploymentSection';
import { EmployeeSalarySection } from './EmployeeSalarySection';
import { EmployeeAttributesSection } from './EmployeeAttributesSection';

export const EmployeeModal: React.FC<BaseModalProps> = ({ name, path }) => {
  const { modalState, close } = useModal();
  const modalData = modalState[name] || { open: false, payload: {} };
  const isOpen = modalData.open;
  const mode = modalData.payload?.mode ?? 'create';
  const employeeId = modalData.payload?.employeeId ?? null;
  const employeeName = modalData.payload?.employeeName ?? '';

  // Local state for form
  const [employee, setEmployee] = useState<EmployeeForm>(initialEmployeeState);

  // File upload
  const [attachedLicenseFiles, setAttachedLicenseFiles] = useState<string[]>([]);
  const { openFilePicker, filesContent, clear } = useFilePicker({ multiple: false });
  useEffect(() => {
    if (filesContent.length > 0) {
      setAttachedLicenseFiles(filesContent.map((file) => file.name));
    }
  }, [filesContent]);

  // Step tracking
  const [steps, setSteps] = useState<Map<string, boolean>>(
    new Map([
      ['Allmänna uppgifter', false],
      ['Kontaktuppgifter', false],
      ['Närmast anhörig', false],
      ['Anställningsuppgifter', false],
      ['Lön', false],
      ['Attributer', false],
    ])
  );

  // GET -> fetch existing data
  const {
    data: fetchedEmployee,
    callAPI: fetchEmployee,
    isLoading: isFetching,
  } = useAPI<EmployeeForm>(`/employee/${employeeId}`, { method: 'GET' });

  // POST -> create new
  const {
    // data: createdEmployee,
    callAPI: createEmployee,
    isLoading: isCreating,
  } = useAPI<EmployeeForm>('/employee', { method: 'POST' });

  // PUT -> update existing
  const {
    //data: updatedEmployee,
    callAPI: updateEmployee,
    isLoading: isUpdating,
  } = useAPI<EmployeeForm>(`/employee/${employeeId}`, { method: 'PUT' });

  //  Reset state whenever we open the modal in "create" mode

  useEffect(() => {
    if (isOpen && mode === 'create') {
      setEmployee({
        ...initialEmployeeState,
        //  each array to start with one empty field:
        languages: [''],
        utbildningar: [''],
        allergier: [''],
        körkort: [],
      });
      setAttachedLicenseFiles([]);
    }
  }, [mode, isOpen]);

  // If in edit mode, fetch existing data once

  useEffect(() => {
    if (isOpen && mode === 'edit' && employeeId) {
      fetchEmployee();
    }
  }, [mode, employeeId, isOpen]);

  // Helper function to normalize fetched employee data
  const normalizeEmployeeData = (data: Partial<EmployeeForm>): EmployeeForm => ({
    ...initialEmployeeState,
    ...data,
    firstName: data.firstName ?? '',
    lastName: data.lastName ?? '',
    personalIdentityNumber: data.personalIdentityNumber ?? '',
    address: data.address ?? '',
    postCode: data.postCode ?? '',
    city: data.city ?? '',
    tel: data.tel ?? '',
    email: data.email ?? '',

    nextOfKin1FirstName: data.nextOfKin1FirstName ?? '',
    nextOfKin1LastName: data.nextOfKin1LastName ?? '',
    nextOfKin1Phone: data.nextOfKin1Phone ?? '',
    nextOfKin1Relation: data.nextOfKin1Relation ?? '',

    nextOfKin2FirstName: data.nextOfKin2FirstName ?? '',
    nextOfKin2LastName: data.nextOfKin2LastName ?? '',
    nextOfKin2Phone: data.nextOfKin2Phone ?? '',
    nextOfKin2Relation: data.nextOfKin2Relation ?? '',

    employmentForm: data.employmentForm ?? '',
    extent: data.extent ?? '',
    startTime: data.startTime?.slice(0, 5) ?? '', // Normalize to "HH:mm"
    endTime: data.endTime?.slice(0, 5) ?? '',
    salaryForm: data.salaryForm ?? '',
    personelType: data.personelType ?? '',

    monthlySalary: data.monthlySalary ?? 0,
    addition: data.addition ?? 0,

    employmentDate: data.employmentDate ?? null,
    endOfEmploymentDate: data.endOfEmploymentDate ?? null,

    languages: data.languages?.length ? data.languages : [''],
    utbildningar: data.utbildningar?.length ? data.utbildningar : [''],
    allergier: data.allergier?.length ? data.allergier : [''],
    körkort: data.körkort ?? [],
  });

  useEffect(() => {
    if (fetchedEmployee) {
      setEmployee(normalizeEmployeeData(fetchedEmployee));
    }
  }, [fetchedEmployee]);

  const handleSectionUpdate = (updatedData: Partial<EmployeeForm>) => {
    setEmployee((prev) => ({ ...prev, ...updatedData }));
  };

  // handleSubmit => POST or PUT

  const handleSubmit = async () => {
    const numericExtent = parseInt(employee.extent, 10);
    if (isNaN(numericExtent) || numericExtent < 1 || numericExtent > 100) {
      console.error('Extent must be a number between 1 and 100.');
      return;
    }

    const processedEmployee = {
      ...employee,
      extent: numericExtent,
      monthlySalary: Number(employee.monthlySalary),
      addition: Number(employee.addition),
    };

    try {
      if (mode === 'edit' && employeeId) {
        await updateEmployee(processedEmployee); // PUT request
        console.log('Employee updated successfully');
      } else {
        await createEmployee(processedEmployee); // POST request
        console.log('Employee created successfully');
      }
      await modalData.payload.refreshData();
      close(name); // Close modal
    } catch (error) {
      console.error('Error submitting employee:', error);
    }
  };

  //  Update Step statuses

  const updateStep = (stepName: string, isComplete: boolean) => {
    setSteps((prev) => {
      const copy = new Map(prev);
      copy.set(stepName, isComplete);
      return copy;
    });
  };

  useEffect(() => {
    // "Allmänna uppgifter"
    updateStep(
      'Allmänna uppgifter',
      employee.firstName.length > 0 &&
        employee.lastName.length > 0 &&
        employee.personalIdentityNumber.length >= 10
    );

    // "Kontaktuppgifter"
    updateStep(
      'Kontaktuppgifter',
      employee.address.length > 0 &&
        employee.postCode.length >= 3 &&
        employee.city.length > 0 &&
        employee.tel.length >= 6 &&
        employee.email.length > 0
    );

    // "Närmast anhörig"
    updateStep(
      'Närmast anhörig',
      employee.nextOfKin1FirstName.length > 0 &&
        employee.nextOfKin1Phone.length > 0 &&
        employee.nextOfKin1Relation.length > 0
    );

    // "Anställningsuppgifter"
    updateStep(
      'Anställningsuppgifter',
      !!employee.employmentDate &&
        (!employee.endOfEmploymentDate || !!employee.endOfEmploymentDate) &&
        employee.employmentForm.length > 0 &&
        employee.extent.length > 0 &&
        employee.startTime.length > 0 &&
        employee.endTime.length > 0 &&
        employee.salaryForm.length > 0 &&
        employee.personelType.length > 0
    );

    // "Lön"
    updateStep('Lön', employee.monthlySalary > 0);

    // "Attributer"
    updateStep(
      'Attributer',
      employee.languages.some((l) => l.trim() !== '') ||
        employee.utbildningar.some((u) => u.trim() !== '') ||
        employee.allergier.some((a) => a.trim() !== '') ||
        employee.körkort.length > 0
    );
  }, [employee]);

  // This will disable the button while any request is in progress
  const isLoading = isCreating || isUpdating || isFetching;

  // Dynamic title
  const ModalTitle =
    mode === 'edit'
      ? `Redigera ${
          employeeName ||
          `${fetchedEmployee?.firstName || ''} ${fetchedEmployee?.lastName || ''}`.trim()
        }${fetchedEmployee?.lastName?.endsWith('s') ? "'" : 's'} information`
      : 'Skapa en ny medarbetare';

  return (
    <BaseModal name={name} path={path} title={ModalTitle}>
      <RowBox>
        {/* Left side: The progress steps display */}
        <ColumnBox sx={{ width: '30%' }}>
          <ProgressStep steps={steps} />
        </ColumnBox>

        {/* Right side: The form itself */}
        <ColumnBox sx={{ width: '70%' }}>
          {/* Allmänna uppgifter */}

          <EmployeeGeneralSection employee={employee} onUpdate={handleSectionUpdate} />
          {/* Kontaktuppgifter */}
          <EmployeeContactSection employee={employee} onUpdate={handleSectionUpdate} />
          {/* Närmast anhörig */}
          <EmployeeKinSection employee={employee} onUpdate={handleSectionUpdate} />
          {/* Anställningsuppgifter */}
          <EmployeeEmploymentSection employee={employee} onUpdate={handleSectionUpdate} />
          {/* Lön */}
          <EmployeeSalarySection employee={employee} onUpdate={handleSectionUpdate} />
          {/* Attributer */}
          <EmployeeAttributesSection
            employee={employee}
            onUpdate={handleSectionUpdate}
            openFilePicker={openFilePicker}
            attachedLicenseFiles={attachedLicenseFiles}
            clearFiles={() => {
              setAttachedLicenseFiles([]);
              clear();
            }}
          />

          {/* Submit button */}
          <Box sx={{ display: 'flex', flexFlow: 'row-reverse', padding: '15px' }}>
            <Button onClick={handleSubmit} disabled={isLoading} variant="contained">
              {isLoading ? 'Sparar...' : 'Spara'}
            </Button>
          </Box>
        </ColumnBox>
      </RowBox>
    </BaseModal>
  );
};

import React from 'react';
import { WidgetLayout, WidgetProps } from '../layouts/WidgetLayout';
import { LineChart, PieChart, BarChart, SparkLineChart } from '@mui/x-charts';

/**
 * Props for the StatWidget component.
 */
interface StatWidgetProps extends WidgetProps {
  /**
   * The type of chart to display.
   * Options: `'pie' | 'bar' | 'line' | 'spark'`.
   */
  variant: 'pie' | 'bar' | 'line' | 'spark';

  /**
   * The data to render in the chart.
   */
  data: any[];

  /**
   * The optional x-axis labels for the chart (used in line and bar charts).
   */
  xAxis?: any[];

  /**
   * A ref for the chart instance (optional).
   */
  ref?: React.LegacyRef<unknown>;
}

/**
 * A widget component for displaying various types of charts, including pie, bar, line, and spark charts.
 *
 * @param {StatWidgetProps} props - The props for the StatWidget component.
 * @returns {React.ReactElement} The rendered StatWidget component.
 *
 * @example
 * <StatWidget
 *    title="Sales Data"
 *    variant="bar"
 *    size={4}
 *    data={[{ name: 'Jan', value: 30 }, { name: 'Feb', value: 50 }]}
 *    xAxis={['Jan', 'Feb']}
 * />
 */
export const StatWidget: React.FC<StatWidgetProps> = ({
  title,
  variant,
  size,
  data,
  xAxis,
  ref,
}) => {
  /**
   * Generates the appropriate chart component based on the `variant` prop.
   *
   * @param {string} variant - The type of chart to display.
   * @param {any[]} dataSource - The data to render in the chart.
   * @returns {React.ReactNode} The chart component.
   */
  const getVariant = (variant: string, dataSource: any[]): React.ReactNode => {
    switch (variant) {
      case 'pie':
        return (
          <PieChart
            height={200}
            ref={ref}
            series={[
              {
                data: dataSource,
                innerRadius: 60,
                cx: 120,
                highlightScope: { fade: 'global', highlight: 'item' },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: 'gray',
                },
              },
            ]}
          />
        );
      case 'bar':
        return <BarChart height={200} ref={ref} xAxis={xAxis} series={[{ data: dataSource }]} />;
      case 'line':
        return (
          <LineChart
            height={200}
            ref={ref}
            series={[{ data: dataSource }]}
            xAxis={[{ data: xAxis }]}
          />
        );
      case 'spark':
        return (
          <SparkLineChart
            data={dataSource}
            ref={ref}
            height={200}
            showHighlight={true}
            showTooltip={true}
          />
        );
      default:
        return (
          <PieChart
            height={200}
            ref={ref}
            series={[
              {
                data: dataSource,
                innerRadius: 60,
                cx: 120,
                highlightScope: { fade: 'global', highlight: 'item' },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: 'gray',
                },
              },
            ]}
          />
        );
    }
  };

  return (
    <WidgetLayout title={title} size={size}>
      {/* Render the selected chart */}
      {getVariant(variant, data)}
    </WidgetLayout>
  );
};

import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Functional } from '../../configs/menuConfig';
import Header from '../../components/common/Header';
import { MenuItem } from '../../configs/menuConfig';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../../components/navigation/Sidebar';

export const drawerCssProps = {
  openWidth: 240,
  closedWidth: 60,
};

const Dashboard: React.FC = () => {
  const [activeItem, setActiveItem] = useState<MenuItem>(
    Functional[0].submenu ? Functional[0].submenu[0] : Functional[0]
  );
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <>
      <Sidebar
        activeItem={activeItem}
        isOpen={isOpen}
        setActiveItem={setActiveItem}
        setIsOpen={setIsOpen}
      />
      {/* Main Content View */}
      <Box
        sx={{
          display: 'flex',
          width: isOpen
            ? `calc(100% - ${drawerCssProps.openWidth}px)`
            : `calc(100% - ${drawerCssProps.closedWidth}px)`,
          flexFlow: 'column',
          padding: '20px 50px 20px 50px',
          transition: 'margin 0.3s',
          marginLeft: isOpen ? `${drawerCssProps.openWidth}px` : `${drawerCssProps.closedWidth}px`,
        }}
      >
        <Header path={`Startsida${activeItem.to}`} />
        <Outlet />
      </Box>
    </>
  );
};

export default Dashboard;

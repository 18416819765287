import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { drawerCssProps } from '../../pages/dashboard/Dashboard';
import { Close } from '@mui/icons-material';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const SidebarProfile = ({ isOpen, setIsOpen }: Props) => {
  const { user } = useAuth0();
  const theme = useTheme();

  console.log(user);

  // Handle toggle drawer
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        width: '100%',
        minWidth: drawerCssProps.openWidth,
        gap: '10px',
      }}
    >
      {/* Profile Picture */}
      <Box
        component="img"
        onClick={toggleDrawer}
        sx={{
          height: 40,
          width: 40,
          borderWidth: '2px',
          border: 'solid',
          padding: '1px',
          borderRadius: 50,
          borderColor: theme.palette.customColors.color1,
          margin: '10px',
          '&:hover': { cursor: 'pointer' },
        }}
        alt="The house from the offer."
        src={
          user
            ? user.picture
            : 'https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250'
        }
      />
      {/* )} */}
      {/* Profile Data */}
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
        <Typography sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
          {user?.user_metadata.full_name}
        </Typography>
        <Typography sx={{ fontSize: '12px' }}>{user?.org_name}</Typography>
      </Box>
      {/* Toggle Button */}
      <IconButton
        onClick={toggleDrawer}
        sx={{ height: 50, width: 50, margin: '10px 0px 0px 50px' }}
      >
        <Close />
      </IconButton>
    </Box>
  );
};

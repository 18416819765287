import { useState } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { error } from '../types/error';

/**
 * A custom hook for making API calls using Axios.
 *
 * @template T - The type of the response data.
 * @param {string} url - The endpoint to call (relative to the API host).
 * @param {AxiosRequestConfig} [defaultOptions] - Default Axios request configuration.
 * @returns {object} An object containing the API response data, loading state, error state, and a `callAPI` function.
 */
export default function useAPI<T>(url: string, defaultOptions?: AxiosRequestConfig) {
  const { getAccessTokenSilently } = useAuth0();

  // Base API host URL (configured via environment variables)
  const host = process.env.REACT_APP_API_URL;

  // State for storing the API response data
  const [data, setData] = useState<T | null>(null);

  // State for tracking the loading status
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // State for tracking any errors that occur during the API call
  const [error, setError] = useState<error | null>(null);

  /**
   * Function to make an API call to the given URL.
   *
   * @param {object} [body] - Optional body to send with the request.
   * @param {AxiosRequestConfig} [overrideOptions] - Optional additional Axios configuration.
   */
  const callAPI = async (body?: object, overrideOptions?: AxiosRequestConfig) => {
    const token = await getAccessTokenSilently();

    setIsLoading(true); // Indicate the request is in progress
    try {
      const response: AxiosResponse<T> = await axios({
        ...defaultOptions,
        ...overrideOptions,
        url: host + url,
        data: body,
        headers: {
          Authorization: `Bearer ${token}`,
          ...defaultOptions?.headers,
          ...overrideOptions?.headers, // Merge headers dynamically
        },
      });
      setData(response.data); // Store the response data
    } catch (error: any) {
      setError(error); // Store the error if the request fails
    } finally {
      setIsLoading(false); // Indicate the request is complete
    }
  };

  // Return the API response data, loading state, error state, and the API call function
  return { data, isLoading, error, callAPI };
}

// /**
//  * A custom hook for making API calls using Axios.
//  *
//  * @template T - The type of the response data.
//  * @param {string} url - The endpoint to call (relative to the API host).
//  * @param {AxiosRequestConfig} [options] - Optional Axios request configuration.
//  * @returns {object} An object containing the API response data, loading state, error state, and a `callAPI` function.
//  *
//  * @example
//  * const { data, isLoading, error, callAPI } = useAPI<User[]>("/users", { method: "GET" });
//  * useEffect(() => {
//  *    callAPI();
//  * }, []);
//  */

// export default function useAPI<T>(url: string, options?: AxiosRequestConfig) {
//   const { getAccessTokenSilently } = useAuth0();

//   // Base API host URL (configured via environment variables)
//   const host = process.env.REACT_APP_API_URL;

//   // State for storing the API response data
//   const [data, setData] = useState<T | null>(null);

//   // State for tracking the loading status
//   const [isLoading, setIsLoading] = useState<boolean>(false);

//   // State for tracking any errors that occur during the API call
//   const [error, setError] = useState<unknown>(null);

//   /**
//    * Function to make an API call to the given URL.
//    *
//    * @param {object} [body] - Optional body to send with the request.
//    */

//   const callAPI = async (body?: object) => {
//     const token = await getAccessTokenSilently();

//     setIsLoading(true); // Indicate the request is in progress
//     try {
//       const response: AxiosResponse<T> = await axios(
//         host + url,
//         body && options
//           ? { ...options, data: body, headers: { Authorization: `Bearer ${token}` } }
//           : { ...options, headers: { Authorization: `Bearer ${token}` } }
//       );
//       setData(response.data); // Store the response data
//     } catch (error: unknown) {
//       setError(error); // Store the error if the request fails
//     } finally {
//       setIsLoading(false); // Indicate the request is complete
//     }
//   };

//   // Return the API response data, loading state, error state, and the API call function
//   return { data, isLoading, error, callAPI };
// }

import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/layouts/PageLayout';
import { SearchSelect } from '../../components/common/SearchSelect';
import { Column, TableWidget } from '../../components/widgets/TableWidget';
import { StatWidget } from '../../components/widgets/StatWidget';
import { Search } from '@mui/icons-material';
import { lineData, monthsData } from '../../utils/Utils';
import useAPI from '../../hooks/useAPI';
import { ServicesTable } from '../../types/servicesTable';
import { Service } from '../../types/service';

const servicesColumn: Column[] = [
  { header: 'Nr.', accessor: 'id', align: 'left' },
  { header: 'Produkt', accessor: 'name', align: 'left' },
  { header: 'Enhet', accessor: 'unit', align: 'left' },
  { header: 'Pris', accessor: 'price', align: 'right' },
];

const Services: React.FC = () => {
  const [mappedTableData, setMappedTableData] = useState<ServicesTable[]>([]);

  const mapDataToTable = (data: Service[] | undefined | null): ServicesTable[] => {
    if (!data) {
      return [];
    }

    return data?.map((e: Service) => {
      return {
        id: e.id,
        name: e.name,
        unit: e.unit ? e.unit : 'ospecificerat',
        price: e.price ? `${e.price} kr` : 'ospecificerat',
        calculationCost: `${e.calculationCost} kr`,
      };
    });
  };

  const { callAPI, data } = useAPI<Service[]>(`/articles/type/SERVICE`);

  useEffect(() => {
    callAPI();
  }, []);

  useEffect(() => {
    setMappedTableData(mapDataToTable(data));
  }, [data]);

  return (
    <PageLayout
      title="Tjänster"
      extras={[
        <SearchSelect
          key="search"
          inputStyle={{ background: 'white' }}
          placeholder="Sök"
          icon={<Search />}
          iconAlign="right"
        />,
      ]}
    >
      <StatWidget
        title="Sålda tjänster"
        variant="bar"
        size={12}
        xAxis={monthsData}
        data={lineData}
      />
      <TableWidget
        title="Tjänster Lista"
        size={12}
        data={mappedTableData}
        columns={servicesColumn}
      />
    </PageLayout>
  );
};

export default Services;

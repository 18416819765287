export const languageOptions = [
  'Svenska',
  'Norska',
  'Danska',
  'Finska',
  'Isländska',
  'Engelska',
  'Tyska',
  'Franska',
  'Spanska',
  'Italienska',
  'Polska',
  'Ryska',
  'Serbiska/Bosniska/Kroatiska (BCS)',
  'Rumänska',
  'Albanska',
  'Arabiska',
  'Persiska (Farsi)',
  'Kurdiska (Sorani/Kurmanji)',
  'Turkiska',
  'Somaliska',
  'Thailändska',
  'Vietnamesiska',
  'Dari',
  'Pashto',
  'Hindi/Urdu',
  'Mandarin (Kinesiska)',
  'Amhariska',
  'Tigrinska',
  'Swahili',
];

export const utbildningarOptions = [
  'SRY-utbildning (Servicebranschens Yrkesnämnd)',
  'PRYL-utbildning (Professionell Rengöring Yrkesinriktad Lokalvård)',
  'Golvvårdsutbildning',
  'Saneringsutbildning',
  'Arbetsmiljöutbildning',
];

export const fobierOptions = [
  'Rädsla för hundar',
  'Rädsla för katter',
  'Rädsla för fåglar (t.ex. duvor)',
  'Rädsla för insekter (t.ex. spindlar, getingar)',
  'Rädsla för höjder (akrofobi)',
  'Rädsla för trånga utrymmen (klaustrofobi)',
  'Rädsla för mörker (nyktofobi), Rädsla för blod (hemofobi)',
  'Rädsla för trängsel/stora folkmassor (agorafobi)',
];

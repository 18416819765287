import { TextField as TextInput } from '@mui/material';
import React from 'react';
import { theme } from '../../configs/theme';

/**
 * Props for the TextField component.
 */
interface TextFieldProps {
  /**
   * Placeholder text for the input field.
   */
  placeholder?: string;

  /**
   * The name of the input field, useful for form handling.
   */
  name?: string;

  /**
   * Callback function triggered when the value changes.
   * @param {React.ChangeEvent<unknown>} e - The change event object.
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * An optional icon to display inside the input field.
   */
  icon?: React.ReactNode;

  /**
   * The alignment of the icon in the input field.
   * Options: `'left'` or `'right'`.
   */
  iconAlign?: 'left' | 'right';

  /**
   * Custom styles for the input field.
   */
  inputStyle?: object;

  /**
   * If true, the input field will be read-only.
   */
  readOnly?: boolean;

  /**
   * Additional styles for the input container.
   */
  sx?: object;

  /**
   * The current value of the input field.
   */
  value?: string | string[];

  /**
   * If true, the input field will support multiple lines of text.
   */
  multiline?: boolean;

  /**
   * Text to display below the input field as helper text.
   */
  helperText?: string;
}

/**
 * A customizable text input component built on Material-UI's TextField.
 *
 * @param {TextFieldProps} props - The props for the TextField component.
 * @returns {React.ReactElement} The rendered TextField component.
 *
 * @example
 * <TextField
 *    placeholder="Enter your text"
 *    name="exampleInput"
 *    value="Hello"
 *    onChange={(e) => console.log(e.target.value)}
 *    icon={<SearchIcon />}
 *    iconAlign="left"
 *    multiline
 *    readOnly
 * />
 */
export const TextField: React.FC<TextFieldProps> = ({
  placeholder,
  onChange,
  icon,
  iconAlign,
  inputStyle = { background: theme.palette.background.default },
  sx = { width: '100%' },
  name,
  value,
  multiline = false,
  readOnly = false,
  helperText,
}) => {
  return (
    <TextInput
      multiline={multiline} // Enable multiline if true
      name={name} // Set the input field's name
      value={value} // Bind the current value to the input
      placeholder={placeholder} // Set the placeholder text
      onChange={onChange} // Handle input changes
      sx={sx} // Apply additional styles
      variant="standard" // Use the 'standard' variant of the Material-UI TextField
      helperText={helperText}
      slotProps={{
        input: {
          readOnly: readOnly, // Set read-only mode if true
          disableUnderline: true, // Remove the underline from the input
          startAdornment: icon && iconAlign === 'left' ? icon : null, // Show icon on the left if specified
          endAdornment: icon && iconAlign === 'right' ? icon : null, // Show icon on the right if specified
          style: {
            ...inputStyle, // Apply custom styles to the input
            border: 'none',
            borderRadius: '8px',
            padding: multiline ? '11px' : '8px', // Adjust padding for multiline mode
          },
        },
      }}
    />
  );
};

import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

/**
 * Props for the ProgressStep component.
 */
interface ProgressStepProps {
  /**
   * A map representing the steps in the progress tracker.
   * The key is the step name, and the value is a boolean indicating whether the step is complete.
   */
  steps: Map<string, boolean>;
}

/**
 * A progress tracker component that displays a series of steps and their completion status.
 *
 * @param {ProgressStepProps} props - The props for the ProgressStep component.
 * @returns {React.ReactElement} The rendered ProgressStep component.
 *
 * @example
 * const steps = new Map([
 *    ['Step 1 Description', true],
 *    ['Step 2 Description', false],
 *    ['Step 3 Description', false]
 * ]);
 *
 * <ProgressStep steps={steps} />
 */
export const ProgressStep: React.FC<ProgressStepProps> = ({ steps }) => {
  const theme = useTheme();

  return (
    <ProgressContainer>
      {/* Iterate through the steps and display them */}
      {Array.from(steps).map(([key, value], index) => (
        <Step key={index}>
          <BoxRow>
            {/* Step index and name */}
            <Typography fontWeight="bold" fontSize="14px">
              Steg {index + 1}
            </Typography>
            <Typography fontStyle="italic" fontSize="14px">
              - {key}
            </Typography>
          </BoxRow>
          <BoxRow>
            {/* Icon indicating whether the step is complete */}
            {value ? (
              <CheckCircleIcon sx={{ color: theme.palette.customColors.color1 }} />
            ) : (
              <RadioButtonUncheckedIcon sx={{ color: theme.palette.customColors.color1 }} />
            )}
          </BoxRow>
        </Step>
      ))}
    </ProgressContainer>
  );
};

/**
 * A styled container for the progress steps.
 */
const ProgressContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.customColors.color2,
  borderRadius: 10,
  padding: '20px',
}));

/**
 * A styled box for each individual step in the progress tracker.
 */
const Step = styled(Box)(() => ({
  padding: '5px',
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

/**
 * A styled row box to align content horizontally.
 */
const BoxRow = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
}));

import React from 'react';
import PageLayout from '../../components/layouts/PageLayout';
import { Reminder } from '../../components/widgets/ReminderWidget';
import { WarningAmber } from '@mui/icons-material';
import { Column, TableWidget } from '../../components/widgets/TableWidget';

interface TimeReportTable {
  employee: string;
  mon: string;
  tue: string;
  wed: string;
  thu: string;
  fri: string;
  sat: string;
  sun: string;
}

const customerColumn: Column[] = [
  { header: 'Ansälld', accessor: 'employee', align: 'center' },
  { header: 'Mån', accessor: 'mon', align: 'center' },
  { header: 'Tis', accessor: 'tue', align: 'center' },
  { header: 'Ons', accessor: 'wed', align: 'center' },
  { header: 'Tors', accessor: 'thu', align: 'center' },
  { header: 'Fre', accessor: 'fri', align: 'center' },
  { header: 'Lör', accessor: 'sat', align: 'center' },
  { header: 'Sön', accessor: 'sun', align: 'center' },
];

const sampleData: TimeReportTable[] = [
  {
    employee: 'Juliu Toyoda',
    mon: '6:00 av 6:00',
    tue: '6:00 av 6:00',
    wed: '6:00 av 6:00',
    thu: '6:00 av 6:00',
    fri: '6:00 av 6:00',
    sat: '6:00 av 6:00',
    sun: '6:00 av 6:00',
  },
  {
    employee: 'Juliu Toyoda',
    mon: '6:00 av 6:00',
    tue: '6:00 av 6:00',
    wed: '6:00 av 6:00',
    thu: '6:00 av 6:00',
    fri: '6:00 av 6:00',
    sat: '6:00 av 6:00',
    sun: '6:00 av 6:00',
  },
  {
    employee: 'Juliu Toyoda',
    mon: '6:00 av 6:00',
    tue: '6:00 av 6:00',
    wed: '6:00 av 6:00',
    thu: '6:00 av 6:00',
    fri: '6:00 av 6:00',
    sat: '6:00 av 6:00',
    sun: '6:00 av 6:00',
  },
  {
    employee: 'Juliu Toyoda',
    mon: '6:00 av 6:00',
    tue: '6:00 av 6:00',
    wed: '6:00 av 6:00',
    thu: '6:00 av 6:00',
    fri: '6:00 av 6:00',
    sat: '6:00 av 6:00',
    sun: '6:00 av 6:00',
  },
];

export const TimeReport: React.FC = () => {
  return (
    <PageLayout title="Tidrapporter">
      <Reminder
        size={3}
        title="Låg tid"
        description="Julius Toyoda"
        when="20 Jan, 5:00 av 8:00"
        icon={<WarningAmber sx={{ color: 'white' }} />}
      />
      <Reminder
        size={3}
        title="Låg tid"
        description="Julius Toyoda"
        when="20 Jan, 5:00 av 8:00"
        icon={<WarningAmber sx={{ color: 'white' }} />}
      />
      <Reminder
        size={3}
        title="Låg tid"
        description="Julius Toyoda"
        when="20 Jan, 5:00 av 8:00"
        icon={<WarningAmber sx={{ color: 'white' }} />}
      />
      <Reminder
        size={3}
        title="Låg tid"
        description="Julius Toyoda"
        when="20 Jan, 5:00 av 8:00"
        icon={<WarningAmber sx={{ color: 'white' }} />}
      />
      <TableWidget title="Alla tidrapporter" size={12} data={sampleData} columns={customerColumn} />
    </PageLayout>
  );
};

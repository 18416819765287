import React from 'react';
import { Contract } from '../../models/Contract';
import { OfferRow } from './types';
import { Circle } from '@mui/icons-material';

/**
 * Status-to-color mapping
 */
const statusColors: Record<Contract['status'], string> = {
  Förfallen: 'orange',
  Utkast: 'grey',
  Skickad: '#345995',
  Godkänd: '#0E8E70',
  Avvisad: '#C1666B',
};

/**
 * Converts a Contract into an OfferRow object for the table.
 */
export function offerRow(contract: Contract): OfferRow {
  const { status, address, city, ...rest } = contract;
  return {
    ...rest,
    status,
    fullAddress: `${address}, ${city}`,
    colorStatus: <Circle sx={{ color: statusColors[status] }} />,
  };
}

/**
 * Returns an array (length 12) of monthly counts for offers that are not 'Utkast'.
 */
export function getMonthlyCounts(data: Contract[]): number[] {
  const months = Array(12).fill(0);
  data.forEach((item) => {
    if (item.status === 'Utkast') return;
    if (item.createdOn) {
      const month = new Date(item.createdOn).getMonth();
      if (!isNaN(month)) {
        months[month]++;
      }
    }
  });
  return months;
}

/**
 * Safely retrieve a nested value from an object based on an "accessor" path (e.g. 'customer.name').
 */
export function getNestedValue<T extends object>(obj: T, accessor: string): unknown {
  // If there is no object or the object is not actually an object, return null
  if (!obj || typeof obj !== 'object') {
    return null;
  }

  let current: unknown = obj;
  const keys = accessor.split('.');

  for (const key of keys) {
    // If `current` is falsy or not an object, we cannot go deeper
    if (!current || typeof current !== 'object') {
      return null;
    }
    // Narrow `current` to a record so we can index by `key`
    const record = current as Record<string, unknown>;

    if (!(key in record)) {
      return null;
    }

    current = record[key];
  }

  return current;
}

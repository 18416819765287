import React, { useEffect, useState } from 'react';
import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Dashboard from './pages/dashboard/Dashboard';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Functional, Resource, Utils, Settings } from './configs/menuConfig';
import { theme } from './configs/theme';
import { ModalProvider } from './contexts/ModalContext';
import { ContractModal } from './pages/contracts/ContractModal';
import { OrderModal } from './pages/order/OrderModal';
import { EmployeeModal } from './pages/resources/employeeModal/EmployeeModal';
import { VehiclesModal } from './pages/resources/VehiclesModal';
import { CustomerModal } from './pages/customers/CustomerModal';
import { CaseModal } from './pages/case/CaseModal';
import { AlertProvider } from './contexts/AlertContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ContractActionModal } from './pages/contracts/ContractActionModal';
import AuthLayout from './components/layouts/AuthLayout';
import { Auth0Provider } from '@auth0/auth0-react';
import { providerConfig } from './auth/providerConfig';
import { FortnoxCallContainer } from './pages/fortnoxCallContainer/FortnoxCallContainer';

/**
 * The main application component that sets up global providers, theme, routing, and modals.
 */

const App: React.FC = () => {
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true);

  useEffect(() => {
    // loader to prevent Auth0 from rendering before the
    // state is deleted that is send from Fortnox.
    // The state is overwritten by fortnox since Auth0 already sets the state
    // of the authentication flow.
    setIsAuthLoading(true);

    // const url = new URL(window.location.href);
    // const params = new URLSearchParams(url.search);

    // const queryObj: Record<string, string> = {};
    // params.forEach((value, key) => {
    //   queryObj[key] = value;
    // });
    // // The value "placeholder" is set by us in
    // if (queryObj.state === 'fortnoxAuthState') {
    //   url.searchParams.delete('state');
    //   window.history.replaceState({}, '', url.toString());
    // }
    setIsAuthLoading(false);
  }, []);

  return (
    <>
      {!isAuthLoading ? (
        <Auth0Provider {...providerConfig}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* Provides alert functionality to the application */}
            <AlertProvider>
              {/* Provides modal state management to the application */}
              <ModalProvider>
                {/* Applies the custom Material-UI theme to the application */}
                <ThemeProvider theme={theme}>
                  {/* Resets browser styling for consistency across different platforms */}
                  <CssBaseline />
                  {/* Configures routing for the application */}
                  <BrowserRouter>
                    <AuthLayout>
                      {/* <FortnoxCallContainer> */}
                      {/* Global Modals */}
                      <OrderModal
                        name="OrderModal"
                        title="Skapa en ny order"
                        path="Startsida/Order/Skapa Order"
                      />
                      <ContractModal
                        name="OfferModal"
                        title="Skapa en ny offert"
                        path="Startsida/Offert/Skapa Offert"
                      />
                      <ContractModal
                        name="ProjectModal"
                        title="Skapa ett nytt projekt"
                        path="Startsida/Projekt/Skapa Projekt"
                      />
                      <EmployeeModal
                        name="EmployeeModal"
                        title="Skapa en ny medarbetare"
                        path="Startsida/Resource/Medarbetare/Skapa medarbetare"
                      />
                      <ContractActionModal
                        name="ContractActionModal"
                        title="Offertförslag"
                        path="Startsida/Offer/Status"
                      />
                      <VehiclesModal
                        name="VehiclesModals"
                        title="Skapa ett nytt fordon"
                        path="Startsida/Resurser/Fordon/Skapa fordon"
                      />
                      <CustomerModal
                        name="CustomerModal"
                        title="Skapa en ny kund"
                        path="Startsida/Kunder/Skapa Kund"
                      />
                      <CaseModal
                        name="CaseModal"
                        title="Skapa ett nytt ärende"
                        path="Startsida/Ärendehantering/Skapa Ärende"
                      />
                      {/* Application routes */}
                      <Routes>
                        {/* Dashboard acts as the base route */}
                        <Route path="/" element={<Dashboard />}>
                          {Functional.concat(Resource, Utils, Settings).map((item, index) => (
                            <React.Fragment key={index}>
                              {/* Route for primary menu item */}
                              <Route path={item.to} element={item.element} />
                              {/* Routes for submenu items if they exist */}
                              {item.submenu &&
                                item.submenu.length > 0 &&
                                item.submenu.map((subItem, subIndex) => (
                                  <Route
                                    key={subIndex}
                                    path={subItem.to}
                                    element={subItem.element}
                                  />
                                ))}
                            </React.Fragment>
                          ))}
                        </Route>
                      </Routes>
                      {/* </FortnoxCallContainer> */}
                    </AuthLayout>
                  </BrowserRouter>
                </ThemeProvider>
              </ModalProvider>
            </AlertProvider>
          </LocalizationProvider>
        </Auth0Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/layouts/PageLayout';
import { SearchSelect } from '../../components/common/SearchSelect';
import { Column, TableWidget } from '../../components/widgets/TableWidget';
import { StatWidget } from '../../components/widgets/StatWidget';
import { Search } from '@mui/icons-material';
import { Order as OrderEntity } from '../../models/Order';
import useAPI from '../../hooks/useAPI';
import { lineData } from '../../utils/Utils';

const orderColumn: Column[] = [
  { header: 'Nr.', accessor: 'id', align: 'left' },
  { header: 'Kund', accessor: 'customer.name', align: 'left' },
  { header: 'Org.Nr', accessor: 'customer.identityNumber', align: 'left' },
  { header: 'Address', accessor: 'customer.address', align: 'center' },
  { header: 'Startdatum', accessor: 'startDate', align: 'left' },
  { header: 'Snittid per vecka', accessor: 'totalValue', align: 'center' },
  { header: 'Värde', accessor: 'totalValue', align: 'right' },
];

export const Order: React.FC = () => {
  const [orders, setOrders] = useState<OrderEntity[]>([]);
  const { data, callAPI } = useAPI<OrderEntity[]>(`/order?relations=${['customer']}`);
  // handle OnEdit
  const handleOnEdit = () => {};

  // handle call api on mount
  useEffect(() => {
    // Fetch data from api
    callAPI();
  }, []);

  // handle on success
  useEffect(() => {
    if (data) {
      setOrders(data);
    }
  }, [data]);

  return (
    <PageLayout
      title="Order"
      onEdit={handleOnEdit}
      extras={[
        //<Button variant='contained' onClick={handleCreateOrder} >Skapa</Button>,
        <SearchSelect
          key="search"
          inputStyle={{ background: 'white' }}
          placeholder="Sök"
          icon={<Search />}
          iconAlign="right"
        />,
      ]}
    >
      <StatWidget
        title="Summering"
        variant="spark"
        size={12}
        data={lineData}
        xAxis={[0, 5, 10, 20, 30, 40]}
      />
      <TableWidget title="Lista" size={12} data={orders} columns={orderColumn} />
    </PageLayout>
  );
};

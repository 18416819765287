import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/layouts/PageLayout';
import { SearchSelect } from '../../components/common/SearchSelect';
import { Column, TableWidget } from '../../components/widgets/TableWidget';
import { StatWidget } from '../../components/widgets/StatWidget';
import { Search } from '@mui/icons-material';
import { lineData, monthsData } from '../../utils/Utils';
import useAPI from '../../hooks/useAPI';
import { ProductTable } from '../../types/productTable';
import { Article } from '../../types/article';

const productColumn: Column[] = [
  { header: 'Nr.', accessor: 'id', align: 'left' },
  { header: 'Produkt', accessor: 'name', align: 'left' },
  { header: 'Enhet', accessor: 'unit', align: 'left' },
  { header: 'Kalkylkostnad', accessor: 'calculationCost', align: 'right' },
  { header: 'Pris', accessor: 'price', align: 'right' },
];

const Products: React.FC = () => {
  const [mappedTableData, setMappedTableData] = useState<ProductTable[]>([]);

  const mapDataToTable = (data: Article[] | undefined | null): ProductTable[] => {
    if (!data) {
      return [];
    }

    return data?.map((e: Article) => {
      return {
        id: e.id,
        name: e.name,
        unit: e.unit ? e.unit : 'ospecificerat',
        price: e.price ? `${e.price} kr` : 'ospecificerat',
        calculationCost: `${e.calculationCost} kr`,
      };
    });
  };

  const { callAPI, data } = useAPI<Article[]>(`/articles/type/STOCK`);

  useEffect(() => {
    callAPI();
  }, []);

  useEffect(() => {
    setMappedTableData(mapDataToTable(data));
  }, [data]);

  return (
    <PageLayout
      title="Produkter"
      extras={[
        <SearchSelect
          key="search"
          inputStyle={{ background: 'white' }}
          placeholder="Sök"
          icon={<Search />}
          iconAlign="right"
        />,
      ]}
    >
      <StatWidget
        title="Sålda produkter"
        variant="bar"
        xAxis={monthsData}
        size={12}
        data={lineData}
      />
      <TableWidget
        title="Produkter Lista"
        size={12}
        data={mappedTableData}
        columns={productColumn}
      />
    </PageLayout>
  );
};

export default Products;

import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/layouts/PageLayout';
import { SearchSelect } from '../../components/common/SearchSelect';
import { Search } from '@mui/icons-material';
import { Column, TableWidget } from '../../components/widgets/TableWidget';
import { StatWidget } from '../../components/widgets/StatWidget';
import { lineData, monthsData } from '../../utils/Utils';
import { Contract } from '../../models/Contract';
import useAPI from '../../hooks/useAPI';
import { useModal } from '../../contexts/ModalContext';

const projectColumn: Column[] = [
  { header: 'Nr.', accessor: 'id', align: 'left' },
  { header: 'Kund', accessor: 'customer.name', align: 'left' },
  { header: 'Org.Nr', accessor: 'customer.identityNumber', align: 'left' },
  { header: 'Adress', accessor: 'fullAddress', align: 'left' },
  { header: 'Startdatum', accessor: '', align: 'center' },
  {
    header: 'Aktiva ordrar',
    accessor: 'customer.activeOrders',
    align: 'center',
  },
  { header: 'Snittid per vecka', accessor: '', align: 'center' },
  { header: 'Värde', accessor: '', align: 'right' },
];

const Project: React.FC = () => {
  // Use modal context
  const { open } = useModal();
  // Use api to fetch contracts
  const { data, callAPI } = useAPI<Contract[]>(`/contract?relations=${['customer']}`);
  const [contracts, setContracts] = useState<Contract[]>([]);

  // handle OnEdit
  const handleOnEdit = () => {};
  // handle Create Project
  const handleCreateProject = () => {
    open('ProjectModal');
  };
  // On Mound
  useEffect(() => {
    // Fetch data from api
    callAPI();
  }, []);

  // Handle on Data
  useEffect(() => {
    if (data) {
      setContracts(
        data
          .filter((data) => data.status === 'Godkänd')
          .map((item) => {
            const { address, city } = item as Contract;
            return {
              ...item,
              fullAddress: `${address}, ${city}`,
            };
          })
      );
    }
  }, [data]);

  return (
    <PageLayout
      title="Projekt"
      onEdit={handleOnEdit}
      extras={[
        <Button key="create" variant="contained" onClick={handleCreateProject}>
          Skapa
        </Button>,
        <SearchSelect
          key="search"
          inputStyle={{ background: 'white' }}
          placeholder="Sök"
          icon={<Search />}
          iconAlign="right"
        />,
      ]}
    >
      <StatWidget title="Summering" variant="bar" size={12} xAxis={monthsData} data={lineData} />
      <TableWidget title="Lista" size={12} data={contracts} columns={projectColumn} />
    </PageLayout>
  );
};

export default Project;

import { Button, Checkbox } from '@mui/material';
import React from 'react';
import PageLayout from '../../components/layouts/PageLayout';
import { SearchSelect } from '../../components/common/SearchSelect';
import { Column, TableWidget } from '../../components/widgets/TableWidget';
import { StatWidget } from '../../components/widgets/StatWidget';
import { Search } from '@mui/icons-material';
import { lineData, monthsData } from '../../utils/Utils';

interface InvoiceTable {
  checkBox: React.ReactNode;
  id: number;
  name: string;
  formOfEmployment: 'Fast' | 'Projektanstänllning';
  billable: string;
  salary: string;
}

const invoicesColumn: Column[] = [
  { header: 'Select', accessor: 'checkBox', align: 'left' },
  { header: 'Nr.', accessor: 'id', align: 'left' },
  { header: 'Medarbetare', accessor: 'name', align: 'left' },
  { header: 'Anställningform', accessor: 'formOfEmployment', align: 'left' },
  { header: 'Beläggning', accessor: 'billable', align: 'left' },
  { header: 'Lön', accessor: 'salary', align: 'right' },
];

const invoiceData: InvoiceTable[] = [
  {
    checkBox: <Checkbox />,
    id: 1,
    name: 'Genc Sadiku',
    formOfEmployment: 'Fast',
    billable: '96%',
    salary: '23,000 kr',
  },
  {
    checkBox: <Checkbox />,
    id: 2,
    name: 'Natalija Schmid',
    formOfEmployment: 'Projektanstänllning',
    billable: '100%',
    salary: '26,000 kr',
  },
  {
    checkBox: <Checkbox />,
    id: 3,
    name: 'Joe Rogan',
    formOfEmployment: 'Projektanstänllning',
    billable: '100%',
    salary: '22,000 kr',
  },
  {
    checkBox: <Checkbox />,
    id: 4,
    name: 'Tom Cruise',
    formOfEmployment: 'Fast',
    billable: '100%',
    salary: '22,000 kr',
  },
];

const Salary: React.FC = () => {
  // handle OnEdit
  const handleOnEdit = () => {};
  // Handle on Close offer
  const handleExport = () => {};
  return (
    <PageLayout
      title="Löneunderlag"
      onEdit={handleOnEdit}
      extras={[
        <Button key="export" variant="contained" onClick={handleExport}>
          Exportera
        </Button>,
        <SearchSelect
          key="search"
          inputStyle={{ background: 'white' }}
          placeholder="Sök"
          icon={<Search />}
          iconAlign="right"
        />,
      ]}
    >
      <StatWidget
        title="Lönekostnader"
        variant="bar"
        size={12}
        xAxis={monthsData}
        data={lineData}
      />
      <TableWidget title="Löne Lista" size={12} data={invoiceData} columns={invoicesColumn} />
    </PageLayout>
  );
};

export default Salary;

import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { theme } from '../../configs/theme';

/**
 * Props for the SearchSelect component.
 */
interface SearchSelectProps {
  /**
   * The selected value or array of selected values.
   */
  value?: string | string[];

  /**
   * Callback function triggered when the value changes.
   * @param {React.ChangeEvent<{ name?: string | undefined; value: unknown; }>} event - The event object with the updated value.
   */
  onChange?: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;

  /**
   * Callback function triggered when the input gains focus.
   */
  onFocus?: () => void;

  /**
   * The name of the input field, useful for form handling.
   */
  name?: string;

  /**
   * Placeholder text for the input field.
   */
  placeholder?: string;

  /**
   * The list of options to display in the dropdown.
   */
  options?: any[];

  /**
   * A function to customize the label for each option.
   * @param {any} option - The current option.
   * @returns {any} The label for the option.
   */
  getOptionLabel?: (option: any) => string;

  /**
   * An optional icon to display inside the input field.
   */
  icon?: React.ReactNode;

  /**
   * The alignment of the icon in the input field.
   * Options: `'right'` or `'left'`.
   */
  iconAlign?: 'right' | 'left';

  /**
   * Additional styles for the autocomplete component.
   */
  sx?: object;

  /**
   * Custom styles for the input field.
   */
  inputStyle?: object;
}

/**
 * A customizable search-select component built with Material-UI's Autocomplete.
 */
export const SearchSelect: React.FC<SearchSelectProps> = ({
  value,
  onFocus,
  options = [],
  onChange,
  placeholder,
  inputStyle = { background: theme.palette.background.default },
  icon,
  iconAlign,
  name,
  sx = { width: '100%' },
  getOptionLabel = (option) => option as string,
}) => {
  /**
   * Handles changes in the Autocomplete component.
   *
   * @param {React.SyntheticEvent} event - The event object from the autocomplete.
   * @param {string | null} newValue - The new selected value.
   */
  const handleAutoCompleteChange = (event: React.SyntheticEvent, newValue: string | null) => {
    if (onChange) {
      // Wrap the arguments into a React.ChangeEvent-like object
      const customEvent = {
        target: {
          name: name || '', // Use the name prop or an empty string
          value: newValue, // Set the new value
        },
      } as React.ChangeEvent<{ name?: string; value: unknown }>;

      onChange(customEvent); // Trigger the provided onChange callback
    }
  };

  return (
    <Autocomplete
      freeSolo // Enable user-defined input
      options={options} // Options for the dropdown
      value={value} // Current value of the autocomplete
      onFocus={onFocus} // Trigger onFocus when input is focused
      getOptionLabel={getOptionLabel} // Customize option labels
      onChange={handleAutoCompleteChange} // Handle value changes
      sx={{ ...sx, width: '100%' }} // Allow custom and full-width styling
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder={placeholder} // Placeholder for the input field
          InputProps={{
            ...params.InputProps, // Ensures dropdown arrow and clear icon render properly
            disableUnderline: true, // Remove underline
            startAdornment: icon && iconAlign === 'left' ? icon : null, // Show icon on the left
            endAdornment: icon && iconAlign === 'right' ? icon : null, // Show icon on the right
            style: {
              ...inputStyle, // Apply custom input styles
              borderRadius: '8px',
              border: 'none',
              padding: '8px',
              width: '100%',
            },
          }}
        />
      )}
    />
  );
};

import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

// Styled TextField
export const SuffixTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.default,
  borderRadius: '8px',
  border: 'none',
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    padding: '12px 3px 12px 3px',
  },
  '& .MuiInputBase-input': {
    padding: '0px 6px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
}));

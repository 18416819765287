import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { TimePicker as TimePickerMUI } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

/**
 * Props for the TimePicker component.
 */
interface TimePickerProps {
  name?: string;
  value?: string; // e.g. "HH:mm" or "" (blank)
  onChange?: (event: any) => void;
}

/**
 * A custom TimePicker that:
 * - Handles '' (empty string) or null as blank (no default to "now")
 * - Preserves custom styling.
 */
export const TimePicker: React.FC<TimePickerProps> = ({ name, value, onChange }) => {
  /**
   * A small helper that:
   * - Returns null if value is empty or undefined
   * - Otherwise tries to parse in "HH:mm"
   */
  const parseTime = (v: string | undefined) => {
    if (!v) return null; // If '', undefined, or null => no time
    const parsed = dayjs(v, 'HH:mm', true); // 'true' => strict parse
    return parsed.isValid() ? parsed : null;
  };

  // Our local Dayjs state, deriving from the "value" prop.
  const [internalValue, setInternalValue] = useState<Dayjs | null>(parseTime(value));

  // Re-parse if "value" changes from parent (e.g. switching modes or data).
  useEffect(() => {
    setInternalValue(parseTime(value));
  }, [value]);

  // Handler for changes from MUI's TimePicker
  const handleChange = (time: Dayjs | null) => {
    // If user clears, we get null => ''
    const formattedValue = time ? time.format('HH:mm') : '';
    setInternalValue(time);
    if (onChange) {
      onChange({
        target: {
          name,
          value: formattedValue,
        },
      });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePickerStyled
        value={internalValue}
        onChange={handleChange}
        ampm={false} // Disable AM/PM
        slotProps={{
          textField: {
            placeholder: '', // Remove placeholder
          },
        }}
      />
    </LocalizationProvider>
  );
};

/**
 * Styled TimePicker component.
 */
const TimePickerStyled = styled(TimePickerMUI)(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.default,
  borderRadius: '8px',
  '.MuiInputBase-input': {
    padding: '14px 3px 14px 10px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

import React, { useState } from 'react';
import { CardLayout } from '../../../components/layouts/CardLayout';
import { RowBox } from '../../../styles/commonStyles';
import { TextWrapper } from '../../../components/common/TextWrapper';
import { EmployeeForm } from '../../../models/EmployeeForm';
import { SuffixTextField } from '../../../components/common/SuffixTextField';
import { InputAdornment } from '@mui/material';

interface EmployeeSalaryProps {
  /**
   * The current employee form data.
   */
  employee: EmployeeForm;

  /**
   * A callback to update the main state in the parent modal.
   */
  onUpdate: (updatedData: Partial<EmployeeForm>) => void;
}

/**
 * Displays the "Lön" (Salary) section
 * for creating/editing an Employee.
 */
export const EmployeeSalarySection: React.FC<EmployeeSalaryProps> = ({ employee, onUpdate }) => {
  // Local states to track focus for each field so we can show/hide the suffix
  const [isSalaryFocused, setIsSalaryFocused] = useState(false);
  const [isAdditionFocused, setIsAdditionFocused] = useState(false);

  /**
   * Handles changes for salary-related fields.
   */
  const handleSalaryChange = (fieldName: string, value: string) => {
    const numericValue = value ? parseInt(value, 10) : 0; // Convert input to a number or set to 0
    onUpdate({ [fieldName]: numericValue });
  };

  return (
    <CardLayout title="Lön" index={5}>
      <RowBox>
        {/* Månadslön */}
        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Månadslön" labelPosition="top">
          <SuffixTextField
            name="monthlySalary"
            value={employee.monthlySalary === 0 ? '' : employee.monthlySalary.toString()}
            onFocus={() => setIsSalaryFocused(true)}
            onBlur={() => {
              if (!employee.monthlySalary) {
                setIsSalaryFocused(false);
              }
            }}
            onChange={(e) => handleSalaryChange('monthlySalary', e.target.value)}
            slotProps={{
              input: {
                endAdornment: isSalaryFocused && (
                  <InputAdornment sx={{ paddingRight: '6px' }} position="end">
                    kr
                  </InputAdornment>
                ),
              },
            }}
          />
        </TextWrapper>

        {/* Tillägg */}
        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Tillägg" labelPosition="top">
          <SuffixTextField
            name="addition"
            value={employee.addition === 0 ? '' : employee.addition.toString()}
            onFocus={() => setIsAdditionFocused(true)}
            onBlur={() => {
              if (!employee.addition) {
                setIsAdditionFocused(false);
              }
            }}
            onChange={(e) => handleSalaryChange('addition', e.target.value)}
            slotProps={{
              input: {
                endAdornment: isAdditionFocused && (
                  <InputAdornment sx={{ paddingRight: '6px' }} position="end">
                    kr
                  </InputAdornment>
                ),
              },
            }}
          />
        </TextWrapper>
      </RowBox>
    </CardLayout>
  );
};

import React from 'react';
// import { LoginPage } from '../../pages/loginPage/LoginPage';
import { useAuth0 } from '@auth0/auth0-react';
import { FullPageSpinner } from '../common/FullPageSpinner';
import { ErrorPageAuth } from '../../pages/error/ErrorPageAuth';

interface Props {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: Props) => {
  const { isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (error) {
    return <ErrorPageAuth error={error} />;
  }

  if (!isAuthenticated && !error && !isLoading) {
    loginWithRedirect();
    return null;
  }

  return <>{children}</>;
};

export default AuthLayout;

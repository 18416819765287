import React from 'react';
import { CardLayout } from '../../../components/layouts/CardLayout';
import { RowBox } from '../../../styles/commonStyles';
import { TextWrapper } from '../../../components/common/TextWrapper';
import { SearchSelect } from '../../../components/common/SearchSelect';
import { Box, Button, IconButton } from '@mui/material';
import { Add, Delete, UploadFile } from '@mui/icons-material';
import { EmployeeForm } from '../../../models/EmployeeForm';
import { languageOptions, utbildningarOptions, fobierOptions } from '../../../models/FormOptions';
import { TextField } from '../../../components/common/TextField';

interface EmployeeAttributesSectionProps {
  employee: EmployeeForm;
  onUpdate: (updatedData: Partial<EmployeeForm>) => void;
  openFilePicker: () => void;
  attachedLicenseFiles: string[];
  clearFiles: () => void;
}

export const EmployeeAttributesSection: React.FC<EmployeeAttributesSectionProps> = ({
  employee,
  onUpdate,
  openFilePicker,
  attachedLicenseFiles,
  clearFiles,
}) => {
  /**
   * Handles adding a new item to an array.
   */
  const handleAdd = (key: keyof EmployeeForm) => {
    const updatedArray = [...(employee[key] as string[]), ''];
    onUpdate({ [key]: updatedArray });
  };

  /**
   * Handles removing an item from an array.
   */
  const handleRemove = (key: keyof EmployeeForm, index: number) => {
    const updatedArray = (employee[key] as string[]).filter((_, i) => i !== index);
    onUpdate({ [key]: updatedArray });
  };

  /**
   * Handles updating a specific item in an array.
   */
  const handleUpdate = (key: keyof EmployeeForm, index: number, value: string) => {
    const updatedArray = (employee[key] as string[]).map((item, i) => (i === index ? value : item));
    onUpdate({ [key]: updatedArray });
  };

  return (
    <CardLayout title="Attributer" index={6}>
      {/* Languages */}
      <TextWrapper sx={{ padding: '20px 20px 1px 20px' }} label="Språk" labelPosition="top">
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {employee.languages.map((language, index) => (
            <RowBox key={index} sx={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
              <SearchSelect
                name={`languages[${index}]`}
                value={language}
                options={languageOptions}
                onChange={(e) => handleUpdate('languages', index, e.target.value as string)}
                sx={{ width: '100%' }}
              />
              <IconButton
                onClick={() => handleRemove('languages', index)}
                disabled={employee.languages.length === 1}
              >
                <Delete />
              </IconButton>
            </RowBox>
          ))}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: '5px',
              marginBottom: '30px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAdd('languages')}
              sx={{ minWidth: '30px', height: '30px', padding: 0 }}
            >
              <Add />
            </Button>
          </Box>
        </Box>
      </TextWrapper>

      {/* Utbildningar */}
      <TextWrapper sx={{ padding: '1px 20px 1px 20px' }} label="Utbildningar" labelPosition="top">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
          {employee.utbildningar.map((utbildning, index) => (
            <RowBox key={index} sx={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
              <SearchSelect
                name={`utbildningar[${index}]`}
                value={utbildning}
                options={utbildningarOptions}
                onChange={(e) => handleUpdate('utbildningar', index, e.target.value as string)}
                sx={{ width: '100%' }}
              />
              <IconButton
                onClick={() => handleRemove('utbildningar', index)}
                disabled={employee.utbildningar.length === 1}
              >
                <Delete />
              </IconButton>
            </RowBox>
          ))}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: '5px',
              marginBottom: '30px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAdd('utbildningar')}
              sx={{ minWidth: '30px', height: '30px', padding: 0 }}
            >
              <Add />
            </Button>
          </Box>
        </Box>
      </TextWrapper>

      {/* Allergier */}
      <TextWrapper sx={{ padding: '1px 20px 1px 20px' }} label="Fobier" labelPosition="top">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
          {employee.allergier.map((fobi, index) => (
            <RowBox key={index} sx={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
              <SearchSelect
                name={`allergier[${index}]`}
                value={fobi}
                options={fobierOptions}
                onChange={(e) => handleUpdate('allergier', index, e.target.value as string)}
                sx={{ width: '100%' }}
              />
              <IconButton
                onClick={() => handleRemove('allergier', index)}
                disabled={employee.allergier.length === 1}
              >
                <Delete />
              </IconButton>
            </RowBox>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '5px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAdd('allergier')}
              sx={{ minWidth: '30px', height: '30px', padding: 0 }}
            >
              <Add />
            </Button>
          </Box>
        </Box>
      </TextWrapper>

      {/* Körkort */}
      <TextWrapper sx={{ padding: '20px' }} label="Körkort" labelPosition="top">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', width: '100%' }}>
          <TextField
            value={attachedLicenseFiles.join(', ')}
            onChange={() => {}}
            sx={{ flexGrow: 1 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={openFilePicker}
            sx={{
              minWidth: '40px',
              height: '40px',
              padding: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <UploadFile />
          </Button>
        </Box>
        {attachedLicenseFiles.length > 0 && (
          <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-start' }}>
            <IconButton onClick={clearFiles}>
              <Delete />
            </IconButton>
          </Box>
        )}
      </TextWrapper>
    </CardLayout>
  );
};

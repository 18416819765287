import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Grid2 } from '@mui/material';
/**
 * Props for the WidgetLayout component.
 */
export interface WidgetProps {
  /**
   * The title of the widget.
   */
  title?: string;

  /**
   * The content to display within the widget.
   */
  children?: React.ReactNode;

  /**
   * Optional additional elements to display in the widget header.
   */
  extras?: React.ReactNode[];

  /**
   * The size of the widget, typically used for layout purposes.
   */
  size?: number;

  /**
   * If true, disables padding around the widget content.
   */
  disablePadding?: boolean;

  /**
   * If true, hides the widget header.
   */
  hideHeader?: boolean;
}

/**
 * A flexible widget layout component for displaying a header and content area.
 *
 * @param {WidgetProps} props - The props for the WidgetLayout component.
 * @returns {React.ReactElement} The rendered WidgetLayout component.
 *
 * @example
 * <WidgetLayout
 *    title="Widget Title"
 *    size={4}
 *    extras={[<Button key="1">Extra</Button>]}
 *    disablePadding={false}
 * >
 *    <Typography>Widget content goes here</Typography>
 * </WidgetLayout>
 */
export const WidgetLayout: React.FC<WidgetProps> = ({
  title,
  children,
  extras,
  size,
  disablePadding = false,
  hideHeader = false,
}) => {
  return (
    <WidgetContainer size={size}>
      {/* Render the widget header if not hidden */}
      {!hideHeader && (
        <WidgetHeader>
          {/* Widget title */}
          <WidgetTitle>{title}</WidgetTitle>
          {/* Render additional header components */}
          <WidgetExtras>
            {extras?.map((item, index) => (
              <Box key={index} sx={{ marginRight: '10px' }}>
                {item}
              </Box>
            ))}
          </WidgetExtras>
        </WidgetHeader>
      )}

      {/* Widget content */}
      <WidgetContent sx={{ padding: disablePadding ? '0px' : '20px' }}>{children}</WidgetContent>
    </WidgetContainer>
  );
};

/**
 * Styled container for the widget content area.
 */
const WidgetContent = styled(Box)(({ theme }) => ({
  background: theme.palette.customColors.color3,
  borderBottomRightRadius: '8px',
  borderBottomLeftRadius: '8px',
}));

/**
 * Styled title for the widget header.
 */
const WidgetTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.customColors.color1,
  padding: '15px',
  fontSize: '18px',
}));

/**
 * Styled container for the widget header.
 */
const WidgetHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'row',
}));

/**
 * Styled container for additional components in the header.
 */
const WidgetExtras = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'row-reverse',
}));

/**
 * Styled container for the entire widget layout.
 */
const WidgetContainer = styled(Grid2)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  borderRadius: '8px',
  background: theme.palette.background.paper,
}));

import React from 'react';
import { CardLayout } from '../../../components/layouts/CardLayout';
import { RowBox } from '../../../styles/commonStyles';
import { TextWrapper } from '../../../components/common/TextWrapper';
import { TextField } from '../../../components/common/TextField';
import { EmployeeForm } from '../../../models/EmployeeForm';
import { Dropdown } from '../../../components/common/Dropdown';

interface EmployeeKinSectionProps {
  /**
   * The current employee form data.
   */
  employee: EmployeeForm;

  /**
   * A callback to update the main state in the parent modal.
   */
  onUpdate: (updatedData: Partial<EmployeeForm>) => void;
}

/**
 * Displays the "Närmast anhörig" (Next of Kin) section
 * for creating/editing an Employee.
 */
export const EmployeeKinSection: React.FC<EmployeeKinSectionProps> = ({ employee, onUpdate }) => {
  /**
   * Handles changes for all next-of-kin fields.
   */
  const handleKinChange = (fieldName: string, value: string) => {
    onUpdate({ [fieldName]: value }); // Update the parent state with the changed field.
  };

  return (
    <CardLayout title="Närmast anhörig" index={3}>
      {/* Anhörig 1 */}
      <RowBox>
        <TextWrapper
          sx={{ padding: '20px', width: '50%' }}
          label="Förnamn (Anhörig 1)"
          labelPosition="top"
        >
          <TextField
            name="nextOfKin1FirstName"
            value={employee.nextOfKin1FirstName}
            onChange={(e) => handleKinChange('nextOfKin1FirstName', e.target.value)}
          />
        </TextWrapper>
        <TextWrapper
          sx={{ padding: '20px', width: '50%' }}
          label="Efternamn (Anhörig 1)"
          labelPosition="top"
        >
          <TextField
            name="nextOfKin1LastName"
            value={employee.nextOfKin1LastName}
            onChange={(e) => handleKinChange('nextOfKin1LastName', e.target.value)}
          />
        </TextWrapper>
      </RowBox>
      <RowBox>
        <TextWrapper
          sx={{ padding: '20px', width: '50%' }}
          label="Telefon (Anhörig 1)"
          labelPosition="top"
        >
          <TextField
            name="nextOfKin1Phone"
            value={employee.nextOfKin1Phone}
            onChange={(e) => handleKinChange('nextOfKin1Phone', e.target.value)}
          />
        </TextWrapper>
        <TextWrapper
          sx={{ padding: '20px', width: '50%' }}
          label="Relation till (Anhörig 1)"
          labelPosition="top"
        >
          <Dropdown
            name="nextOfKin1Relation"
            value={employee.nextOfKin1Relation}
            onChange={(e) => handleKinChange('nextOfKin1Relation', e.target.value)}
            data={[
              'Make/Maka',
              'Sambo',
              'Partner',
              'Förälder',
              'Barn',
              'Syskon',
              'Mor-/Farförälder',
              'Barnbarn',
              'Annan',
            ]}
          />
        </TextWrapper>
      </RowBox>

      {/* Anhörig 2 */}
      <RowBox>
        <TextWrapper
          sx={{ padding: '20px', width: '50%' }}
          label="Förnamn (Anhörig 2)"
          labelPosition="top"
        >
          <TextField
            name="nextOfKin2FirstName"
            value={employee.nextOfKin2FirstName}
            onChange={(e) => handleKinChange('nextOfKin2FirstName', e.target.value)}
          />
        </TextWrapper>
        <TextWrapper
          sx={{ padding: '20px', width: '50%' }}
          label="Efternamn (Anhörig 2)"
          labelPosition="top"
        >
          <TextField
            name="nextOfKin2LastName"
            value={employee.nextOfKin2LastName}
            onChange={(e) => handleKinChange('nextOfKin2LastName', e.target.value)}
          />
        </TextWrapper>
      </RowBox>
      <RowBox>
        <TextWrapper
          sx={{ padding: '20px', width: '50%' }}
          label="Telefon (Anhörig 2)"
          labelPosition="top"
        >
          <TextField
            name="nextOfKin2Phone"
            value={employee.nextOfKin2Phone}
            onChange={(e) => handleKinChange('nextOfKin2Phone', e.target.value)}
          />
        </TextWrapper>
        <TextWrapper
          sx={{ padding: '20px', width: '50%' }}
          label="Relation till (Anhörig 2)"
          labelPosition="top"
        >
          <Dropdown
            name="nextOfKin2Relation"
            value={employee.nextOfKin2Relation}
            onChange={(e) => handleKinChange('nextOfKin2Relation', e.target.value)}
            data={[
              'Make/Maka',
              'Sambo',
              'Partner',
              'Förälder',
              'Barn',
              'Syskon',
              'Mor-/Farförälder',
              'Barnbarn',
              'Annan',
            ]}
          />
        </TextWrapper>
      </RowBox>
    </CardLayout>
  );
};

import React from 'react';
import { CardLayout } from '../../../components/layouts/CardLayout';
import { RowBox } from '../../../styles/commonStyles';
import { TextWrapper } from '../../../components/common/TextWrapper';
import { TextField } from '../../../components/common/TextField';
import { EmployeeForm } from '../../../models/EmployeeForm';

interface EmployeeGeneralSectionProps {
  /**
   * The current employee form data.
   */
  employee: EmployeeForm;

  /**
   * A callback to update the main state in the parent modal.
   */
  onUpdate: (updatedData: Partial<EmployeeForm>) => void;
}

/**
 * Displays the "Allmänna uppgifter" (General Information) section
 * for creating/editing an Employee.
 */
export const EmployeeGeneralSection: React.FC<EmployeeGeneralSectionProps> = ({
  employee,
  onUpdate,
}) => {
  /**
   * Handles changes for all general information fields.
   */
  const handleGeneralChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(`Updated field: ${name}, Value: ${value}`); // Debug log
    onUpdate({ [name]: value }); // Pass updated field to parent
  };

  return (
    <CardLayout title="Allmänna uppgifter" index={1}>
      {/* First name + Last name */}
      <RowBox>
        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Förnamn" labelPosition="top">
          <TextField name="firstName" value={employee.firstName} onChange={handleGeneralChange} />
        </TextWrapper>

        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Efternamn" labelPosition="top">
          <TextField name="lastName" value={employee.lastName} onChange={handleGeneralChange} />
        </TextWrapper>
      </RowBox>

      {/* Personnummer */}
      <RowBox sx={{ alignItems: 'center' }}>
        <TextWrapper
          sx={{ padding: '20px', width: '50%' }}
          label="Personnummer"
          labelPosition="top"
        >
          <TextField
            name="personalIdentityNumber"
            value={employee.personalIdentityNumber}
            onChange={handleGeneralChange}
            helperText="format: XXXXXXXXXX"
          />
        </TextWrapper>
      </RowBox>
    </CardLayout>
  );
};

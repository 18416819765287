// import React, { useState } from 'react';
// import { CardLayout } from '../../../components/layouts/CardLayout';
// import { RowBox } from '../../../styles/commonStyles';
// import { TextWrapper } from '../../../components/common/TextWrapper';
// import { EmployeeForm } from '../../../models/EmployeeForm';
// import { TimePicker } from '../../../components/common/TimePicker';
// import { DatePicker } from '../../../components/common/DatePicker';
// import { Dropdown } from '../../../components/common/Dropdown';
// import { SuffixTextField } from '../../../components/common/SuffixTextField';
// import { InputAdornment } from '@mui/material';

// /**
//  * Props for displaying/editing "Anställningsuppgifter."
//  */
// interface EmployeeEmploymentSectionProps {
//   /**
//    * The current employee form data.
//    */
//   employee: EmployeeForm;

//   /**
//    * A generic onChange handler to update the employee state.
//    */
//   onChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
// }

// /**
//  * Displays the "Anställningsuppgifter" (Employment Information) section
//  * for creating/editing an Employee.
//  */
// export const EmployeeEmploymentSection: React.FC<EmployeeEmploymentSectionProps> = ({
//   employee,
//   onChange,
// }) => {
//   // Local state to handle the focus logic for the "extent" suffix
//   const [isExtentFocused, setIsExtentFocused] = useState(false);

//   /**
//    * Some date pickers (like your DatePicker) might not produce a typical
//    * `e.target.value`. We can create a small helper to dispatch a
//    * synthetic event for the parent’s onChange.
//    */
//   const handleDateChange = (
//     fieldName: string,
//     newValue: string | null // e.g. "YYYY-MM-DD" or null
//   ) => {
//     onChange({
//       target: { name: fieldName, value: newValue || '' },
//     } as React.ChangeEvent<{ name?: string; value: unknown }>);
//   };

//   return (
//     <CardLayout title="Anställningsuppgifter" index={4}>
//       <RowBox>
//         <TextWrapper
//           sx={{ padding: '20px', width: '50%' }}
//           label="Anställningsdatum"
//           labelPosition="top"
//         >
//           <DatePicker
//             name="employmentDate"
//             value={employee.employmentDate || null}
//             // Instead of directly calling setEmployee,
//             // we call handleDateChange to create a synthetic event
//             onChange={(dateEvent) => handleDateChange('employmentDate', dateEvent.target.value)}
//           />
//         </TextWrapper>

//         <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Slutdatum" labelPosition="top">
//           <DatePicker
//             name="endOfEmploymentDate"
//             value={employee.endOfEmploymentDate || null}
//             onChange={(dateEvent) =>
//               handleDateChange('endOfEmploymentDate', dateEvent.target.value)
//             }
//           />
//         </TextWrapper>
//       </RowBox>

//       <RowBox>
//         <TextWrapper
//           sx={{ padding: '20px', width: '50%' }}
//           label="Anställningsform"
//           labelPosition="top"
//         >
//           <Dropdown
//             name="employmentForm"
//             value={employee.employmentForm}
//             onChange={onChange}
//             data={[
//               'Tillsvidareanställning',
//               'Provanställning',
//               'Allmän visstidsanställning',
//               'Särskild visstidsanställning',
//               'Vikariat',
//               'Projektanställning',
//               'Praktikarbete',
//               'Feriearbete',
//               'Säsongsanställning',
//               'Ej anställd',
//             ]}
//           />
//         </TextWrapper>

//         <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Omfattning" labelPosition="top">
//           <SuffixTextField
//             name="extent"
//             value={employee.extent}
//             onFocus={() => setIsExtentFocused(true)}
//             onBlur={() => {
//               if (!employee.extent) {
//                 setIsExtentFocused(false);
//               }
//             }}
//             onChange={onChange}
//             slotProps={{
//               input: {
//                 endAdornment: isExtentFocused && (
//                   <InputAdornment sx={{ paddingRight: '6px' }} position="end">
//                     %
//                   </InputAdornment>
//                 ),
//               },
//             }}
//           />
//         </TextWrapper>
//       </RowBox>

//       <RowBox>
//         <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Starttid" labelPosition="top">
//           <TimePicker name="startTime" value={employee.startTime} onChange={onChange} />
//         </TextWrapper>
//         <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Sluttid" labelPosition="top">
//           <TimePicker name="endTime" value={employee.endTime || ''} onChange={onChange} />
//         </TextWrapper>
//       </RowBox>

//       <RowBox>
//         <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Löneform" labelPosition="top">
//           <Dropdown
//             name="salaryForm"
//             value={employee.salaryForm}
//             onChange={onChange}
//             data={['Månadslön', 'Timlön']}
//           />
//         </TextWrapper>
//         <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Personaltyp" labelPosition="top">
//           <Dropdown
//             name="personelType"
//             value={employee.personelType}
//             onChange={onChange}
//             data={['Tjänsteman', 'Arbetare']}
//           />
//         </TextWrapper>
//       </RowBox>
//     </CardLayout>
//   );
// };

import React, { useState } from 'react';
import { CardLayout } from '../../../components/layouts/CardLayout';
import { RowBox } from '../../../styles/commonStyles';
import { TextWrapper } from '../../../components/common/TextWrapper';
import { EmployeeForm } from '../../../models/EmployeeForm';
import { TimePicker } from '../../../components/common/TimePicker';
import { DatePicker } from '../../../components/common/DatePicker';
import { Dropdown } from '../../../components/common/Dropdown';
import { SuffixTextField } from '../../../components/common/SuffixTextField';
import { InputAdornment } from '@mui/material';

/**
 * Props for displaying/editing "Anställningsuppgifter."
 */
interface EmployeeEmploymentSectionProps {
  /**
   * The current employee form data.
   */
  employee: EmployeeForm;

  /**
   * A callback to update the main state in the parent modal.
   */
  onUpdate: (updatedData: Partial<EmployeeForm>) => void;
}

/**
 * Displays the "Anställningsuppgifter" (Employment Information) section
 * for creating/editing an Employee.
 */
export const EmployeeEmploymentSection: React.FC<EmployeeEmploymentSectionProps> = ({
  employee,
  onUpdate,
}) => {
  // Local state to handle the focus logic for the "extent" suffix
  const [isExtentFocused, setIsExtentFocused] = useState(false);

  /**
   * Handles changes for employment-related fields.
   */
  const handleEmploymentChange = (fieldName: string, value: string | null) => {
    onUpdate({ [fieldName]: value });
  };

  return (
    <CardLayout title="Anställningsuppgifter" index={4}>
      <RowBox>
        <TextWrapper
          sx={{ padding: '20px', width: '50%' }}
          label="Anställningsdatum"
          labelPosition="top"
        >
          <DatePicker
            name="employmentDate"
            value={employee.employmentDate || null}
            onChange={(e) => handleEmploymentChange('employmentDate', e.target.value)}
          />
        </TextWrapper>

        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Slutdatum" labelPosition="top">
          <DatePicker
            name="endOfEmploymentDate"
            value={employee.endOfEmploymentDate || null}
            onChange={(e) => handleEmploymentChange('endOfEmploymentDate', e.target.value)}
          />
        </TextWrapper>
      </RowBox>

      <RowBox>
        <TextWrapper
          sx={{ padding: '20px', width: '50%' }}
          label="Anställningsform"
          labelPosition="top"
        >
          <Dropdown
            name="employmentForm"
            value={employee.employmentForm}
            onChange={(e) => handleEmploymentChange('employmentForm', e.target.value)}
            data={[
              'Tillsvidareanställning',
              'Provanställning',
              'Allmän visstidsanställning',
              'Särskild visstidsanställning',
              'Vikariat',
              'Projektanställning',
              'Praktikarbete',
              'Feriearbete',
              'Säsongsanställning',
              'Ej anställd',
            ]}
          />
        </TextWrapper>

        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Omfattning" labelPosition="top">
          <SuffixTextField
            name="extent"
            value={employee.extent}
            onFocus={() => setIsExtentFocused(true)}
            onBlur={() => {
              if (!employee.extent) setIsExtentFocused(false);
            }}
            onChange={(e) => handleEmploymentChange('extent', e.target.value)}
            slotProps={{
              input: {
                endAdornment: isExtentFocused && (
                  <InputAdornment sx={{ paddingRight: '6px' }} position="end">
                    %
                  </InputAdornment>
                ),
              },
            }}
          />
        </TextWrapper>
      </RowBox>

      <RowBox>
        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Starttid" labelPosition="top">
          <TimePicker
            name="startTime"
            value={employee.startTime}
            onChange={(e) => handleEmploymentChange('startTime', e.target.value)}
          />
        </TextWrapper>
        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Sluttid" labelPosition="top">
          <TimePicker
            name="endTime"
            value={employee.endTime || ''}
            onChange={(e) => handleEmploymentChange('endTime', e.target.value)}
          />
        </TextWrapper>
      </RowBox>

      <RowBox>
        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Löneform" labelPosition="top">
          <Dropdown
            name="salaryForm"
            value={employee.salaryForm}
            onChange={(e) => handleEmploymentChange('salaryForm', e.target.value)}
            data={['Månadslön', 'Timlön']}
          />
        </TextWrapper>
        <TextWrapper sx={{ padding: '20px', width: '50%' }} label="Personaltyp" labelPosition="top">
          <Dropdown
            name="personelType"
            value={employee.personelType}
            onChange={(e) => handleEmploymentChange('personelType', e.target.value)}
            data={['Tjänsteman', 'Arbetare']}
          />
        </TextWrapper>
      </RowBox>
    </CardLayout>
  );
};

import React, { useState } from 'react';
import { MenuItem } from '../../configs/menuConfig';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * Props for the MenuList component.
 */
interface MenuProps {
  /**
   * Array of menu items to display.
   */
  menuItem: MenuItem[];

  /**
   * The currently active menu item.
   */
  activeItem: MenuItem;

  /**
   * Boolean to determine whether the menu is open or collapsed.
   */
  isOpen: boolean;

  /**
   * Callback function triggered when a menu item is clicked.
   * @param {MenuItem} title - The selected menu item.
   */
  onClickItem: (title: MenuItem) => void;
}

/**
 * A dynamic menu list component with collapsible submenus.
 *
 * @param {MenuProps} props - The props for the MenuList component.
 * @returns {React.ReactElement} The rendered MenuList component.
 *
 * @example
 * <MenuList
 *    menuItem={[
 *       { title: 'Dashboard', icon: <DashboardIcon /> },
 *       {
 *          title: 'Settings',
 *          icon: <SettingsIcon />,
 *          submenu: [{ title: 'Profile' }, { title: 'Account' }]
 *       },
 *    ]}
 *    activeItem={{ title: 'Dashboard', icon: <DashboardIcon /> }}
 *    isOpen={true}
 *    onClickItem={(item) => console.log(item.title)}
 * />
 */
const MenuList: React.FC<MenuProps> = ({ menuItem, activeItem, isOpen, onClickItem }) => {
  /**
   * State to track the open/close state of submenus.
   */
  const [submenuState, setSubmenuState] = useState<{ [key: string]: boolean }>({});

  /**
   * Toggles the visibility of a submenu.
   *
   * @param {string} title - The title of the menu item whose submenu should be toggled.
   */
  const handleSubmenuToggle = (title: string) => {
    setSubmenuState((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  return (
    <List>
      {menuItem.map((item, index) => (
        <div key={index}>
          {/* Main Menu Item */}
          <ListItem
            onClick={() => {
              handleSubmenuToggle(item.title);
              onClickItem(item);
            }}
            sx={{
              '&:hover *': {
                color: 'rgb(84, 138, 115)', // Highlight on hover
                cursor: 'pointer',
              },
              '*': {
                color: activeItem.title === item.title ? 'rgb(84, 138, 115)' : 'gray', // Active item color
              },
            }}
          >
            {/* Menu Item Icon */}
            <ListItemIcon>{item.icon}</ListItemIcon>

            {/* Menu Item Title */}
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{ sx: { minWidth: '100px' } }}
            />

            {/* Expand/Collapse Icon for Submenu */}
            {item.submenu && (
              <span>{submenuState[item.title] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
            )}
          </ListItem>

          {/* Submenu */}
          {item.submenu && (
            <Collapse in={submenuState[item.title]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.submenu.map((subItem, subIndex) => (
                  <ListItem
                    onClick={() => onClickItem(subItem)}
                    key={subIndex}
                    sx={{
                      '&:hover *': {
                        color: 'rgb(84, 138, 115)', // Highlight on hover
                        cursor: 'pointer',
                      },
                      '*': {
                        color: activeItem.title === subItem.title ? '#548A73' : 'gray', // Active submenu item color
                      },
                    }}
                  >
                    {/* Submenu Item Icon */}
                    <ListItemIcon></ListItemIcon>

                    {/* Submenu Item Title */}
                    {isOpen && (
                      <ListItemText
                        primaryTypographyProps={{ sx: { fontSize: '14px' } }}
                        primary={subItem.title}
                      />
                    )}
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </div>
      ))}
    </List>
  );
};

export default MenuList;

import { styled } from '@mui/material/styles';
import { Box, Container, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';
import micronIq from '../../assets/images/micronIq_icon.png';
import { Close } from '@mui/icons-material';
import { addSpaceAroundCharacter } from '../../utils/Utils';
import { useModal } from '../../contexts/ModalContext';

/**
 * Props for the BaseModal component.
 */
export interface BaseModalProps {
  /**
   * The title of the modal.
   */
  title?: string;

  /**
   * The unique name of the modal, used to track its open/close state.
   */
  name: string;

  /**
   * The breadcrumb-like path text to display in the modal header.
   */
  path: string;

  /**
   * The main content to render inside the modal.
   */
  children?: React.ReactNode;

  /**
   * Optional additional elements to display in the modal header.
   */
  extras?: React.ReactNode[];
}

/**
 * A reusable modal component with a header, customizable title, and dynamic content area.
 */
export const BaseModal: React.FC<BaseModalProps> = ({ title, name, path, children, extras }) => {
  // Access modal state and close function from context
  const { modalState, close } = useModal();

  // If the modal has never been opened before, modalState[name] might be undefined
  const modalData = modalState[name] || { open: false, payload: null };

  /**
   * Handles the close action for the modal.
   */
  const handleOnClose = () => {
    close(name);
  };

  return (
    <ModalContainer open={modalData.open}>
      <ModalView>
        {/* Modal Header */}
        <ModalHeader>
          {/* Breadcrumb Section */}
          <FlexRowBox>
            <ModalHeaderIcon src={micronIq} />
            <PathText>{addSpaceAroundCharacter(path, '/')}</PathText>
          </FlexRowBox>

          {/* Close Button */}
          <FlexRowBox>
            <IconButton onClick={handleOnClose}>
              <Close sx={{ width: 40, height: 40 }} />
            </IconButton>
          </FlexRowBox>
        </ModalHeader>

        {/* Modal Content */}
        <ModelContent>
          {/* Title and Extras */}
          <ModalHeader>
            <FlexRowBox>
              <ModalTitle>{title}</ModalTitle>
            </FlexRowBox>
            <FlexRowBox>
              {extras?.map((item, index) => (
                <Box key={index} sx={{ marginRight: '10px' }}>
                  {item}
                </Box>
              ))}
            </FlexRowBox>
          </ModalHeader>
          <ModelContent>{children}</ModelContent>
        </ModelContent>
      </ModalView>
    </ModalContainer>
  );
};

// --- styles omitted for brevity; same as your code ---
const ModalContainer = styled(Modal)(() => ({
  overflow: 'scroll',
}));
const ModelContent = styled(Container)(() => ({
  paddingTop: 30,
}));
const ModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontStyle: 'italic',
  color: theme.palette.customColors.color1,
  fontSize: 50,
}));
const PathText = styled(Typography)(() => ({
  fontWeight: 'bold',
  color: 'gray',
  fontSize: '14px',
}));
const FlexRowBox = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
}));
const ModalView = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  minHeight: '100vh',
  display: 'flex',
  flexFlow: 'column',
  padding: '30px',
}));
const ModalHeader = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
const ModalHeaderIcon = styled('img')(() => ({
  borderRadius: 50,
  height: 40,
  width: 40,
  marginRight: 10,
}));

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ColumnBox = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'column',
}));

export const RowBox = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'row',
}));

import React, { useContext, createContext, useState } from 'react';

/**
 * Interface for the shape of a single modal's state:
 *   - open: whether the modal is open or not.
 *   - payload: any extra data you want to pass to the modal.
 */
interface SingleModalState {
  open: boolean;
  payload?: any; // Could be typed more strictly if you wish
}

/**
 * Interface representing the state for **all** modals:
 * Each key is a modal name, and each value is a `SingleModalState`.
 */
interface ModalState {
  [modalName: string]: SingleModalState;
}

/**
 * Interface for the modal context, defining the state and handlers for modals.
 */
interface ModalType {
  /**
   * The current open/close + payload state of all modals.
   */
  modalState: ModalState;

  /**
   * Opens a modal by its name, optionally passing a payload.
   */
  open: (modalName: string, payload?: any) => void;

  /**
   * Closes a modal by its name.
   */
  close: (modalName: string) => void;
}

/**
 * Creates a context for managing modal states and actions.
 */
const ModalContext = createContext<ModalType | null>(null);

/**
 * A provider component that wraps its children with modal state management functionality.
 */
export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // State to manage open/close + payload for all modals
  const [modalState, setModalState] = useState<ModalState>({});

  /**
   * Opens a modal, optionally passing a payload.
   */
  const open = (modalName: string, payload?: any) => {
    setModalState((prev) => ({
      ...prev,
      [modalName]: {
        open: true,
        payload,
      },
    }));
  };

  /**
   * Closes a modal, clearing its payload.
   */
  const close = (modalName: string) => {
    setModalState((prev) => ({
      ...prev,
      [modalName]: {
        open: false,
        payload: null,
      },
    }));
  };

  return (
    <ModalContext.Provider value={{ modalState, open, close }}>{children}</ModalContext.Provider>
  );
};

/**
 * A custom hook for accessing modal state and actions.
 */
export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within ModalProvider');
  }
  return context;
};

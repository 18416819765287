import { Search } from '@mui/icons-material';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import micronIq from '../../assets/images/micronIq_icon.png';
import { addSpaceAroundCharacter } from '../../utils/Utils';

/**
 * Props for the Header component.
 */
interface HeaderProps {
  /**
   * The path to display as a breadcrumb-like text in the header.
   */
  path: string;
}

/**
 * A header component with a logo, a collapsible search bar, and a breadcrumb-like path display.
 *
 * @param {HeaderProps} props - The props for the Header component.
 * @returns {React.ReactElement} The rendered Header component.
 *
 * @example
 * <Header path="home/settings/profile" />
 */
const Header: React.FC<HeaderProps> = ({ path }) => {
  /**
   * State to toggle the visibility and size of the search bar.
   */
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);

  /**
   * Toggles the `isSearchActive` state when the search icon is clicked.
   */
  const handleOnClickSearch = () => {
    setIsSearchActive(!isSearchActive);
  };

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', width: '100%' }}>
      {/* Search Bar section */}
      <Box sx={{ display: 'flex', flexFlow: 'row', width: '100%' }}>
        {/* Logo */}
        <Box
          component="img"
          sx={{ height: 40, width: 40, borderRadius: 50 }}
          src={micronIq}
          alt="MicronIQ Logo"
        />
        {/* Search Field */}
        <TextField
          id="input-with-icon-textfield"
          label=""
          sx={{
            marginLeft: '20px',
            bgcolor: 'white',
            borderRadius: 50,
            transition: 'width 0.3s',
            width: isSearchActive ? '100%' : '40px',
          }}
          slotProps={{
            input: {
              disableUnderline: true,
              startAdornment: (
                <IconButton
                  onClick={handleOnClickSearch}
                  sx={{ height: 40, width: 40, bgcolor: 'white' }}
                >
                  <Search />
                </IconButton>
              ),
            },
          }}
          variant="standard"
        />
      </Box>
      {/* Path Section */}
      <Typography color="grey" fontSize="14px" padding="20px 0px 20px 0px">
        {addSpaceAroundCharacter(path, '/')}
      </Typography>
    </Box>
  );
};

export default Header;

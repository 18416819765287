import React, { useEffect, useState } from 'react';
import { BaseModal, BaseModalProps } from '../../components/modals/BaseModal';
import { Box, Button } from '@mui/material';
import { ColumnBox, RowBox } from '../../styles/commonStyles';
import { ProgressStep } from '../../components/common/ProgressStep';
import { CardLayout } from '../../components/layouts/CardLayout';
import { TextWrapper } from '../../components/common/TextWrapper';
import { Dropdown } from '../../components/common/Dropdown';
import { AssuredWorkload, Badge, Call, Email, LocationOn, Payment, Pin } from '@mui/icons-material';
import { TextField } from '../../components/common/TextField';
import HomeIcon from '@mui/icons-material/Home';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import { Customer } from '../../models/Customer';
import useAPI from '../../hooks/useAPI';
import { useModal } from '../../contexts/ModalContext';

export const CustomerModal: React.FC<BaseModalProps> = ({ title, name, path }) => {
  // Use modal context
  const { close } = useModal();
  const [steps, setSteps] = useState<Map<string, boolean>>(
    new Map([
      ['Allmänna uppgifter', false],
      ['Faktureringsuppgifter', false],
    ])
  );
  const [customer, setCustomer] = useState<Customer>({
    type: 'Företag',
    identityNumber: '',
    name: '',
    tel: '',
    email: '',
    address: '',
    addressAlt: '',
    postNumber: '',
    location: '',
    paymentTerm: '',
  });
  // request to register customer
  const { data, isLoading, callAPI } = useAPI<Customer>('/customer', {
    method: 'POST',
    data: customer,
  });
  // Update step
  const updateStep = (stepName: string, value: boolean) => {
    setSteps((prevSteps) => {
      const updatedSteps = new Map(prevSteps); // Copy the current map
      updatedSteps.set(stepName, value); // Update the specific step value
      return updatedSteps; // Return the updated map
    });
  };
  // Handle on change
  const handleOnChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    // deconstruct event
    const { name, value } = e.target;
    setCustomer((prev) => ({
      ...prev,
      [name as string]: value,
    }));
  };

  // Handle Save form
  const handleSubmit = () => {
    // request registration
    callAPI();
  };

  // Update Steps based on employees properties
  useEffect(() => {
    // Update Progress Step
    updateStep(
      'Allmänna uppgifter',
      customer.type.length > 0 &&
        customer.type.length > 0 &&
        customer.name.length > 0 &&
        customer.identityNumber.length > 0 &&
        customer.tel.length > 0 &&
        customer.email.length > 0
    );
    updateStep(
      'Faktureringsuppgifter',
      customer.address.length > 0 &&
        customer.addressAlt.length > 0 &&
        customer.paymentTerm.length > 0 &&
        customer.postNumber.length > 0 &&
        customer.location.length > 0
    );
  }, [customer]);

  // When data is loaded close modal and refersh page
  useEffect(() => {
    // Print out employee when is updated
    if (data) {
      // success
      close(name);
    }
  }, [data]);

  return (
    <BaseModal name={name} path={path} title={title}>
      <RowBox>
        {/* Informaiton Side */}
        <ColumnBox sx={{ width: '30%' }}>
          <ProgressStep steps={steps} />
        </ColumnBox>
        {/* Content Side */}
        <ColumnBox sx={{ width: '70%' }}>
          <CardLayout title="Allmänna uppgifter" index={1} description="">
            <RowBox>
              <TextWrapper
                label="Kundtyp"
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <Dropdown
                  name="type"
                  onChange={handleOnChange}
                  title="Företag"
                  icon={<AssuredWorkload sx={{ color: 'gray', paddingLeft: '5px' }} />}
                  data={['Företag', 'Privat']}
                />
              </TextWrapper>
              <TextWrapper
                label={customer.type === 'Företag' ? 'Organisationsnummer' : 'Personnummer'}
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <TextField
                  name="identityNumber"
                  placeholder="Enter number"
                  onChange={handleOnChange}
                  icon={<Pin sx={{ color: 'gray', marginRight: '5px' }} />}
                  iconAlign="left"
                />
              </TextWrapper>
            </RowBox>
            <RowBox sx={{ width: '100%' }}>
              <TextWrapper
                label={customer.type === 'Företag' ? 'Företagsnamn' : 'Förnamn & Efternamn'}
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <TextField
                  name="name"
                  onChange={handleOnChange}
                  placeholder="Enter name"
                  icon={<Badge sx={{ color: 'gray', marginRight: '5px' }} />}
                  iconAlign="left"
                />
              </TextWrapper>
            </RowBox>
            <RowBox>
              <TextWrapper
                label="Telefon"
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <TextField
                  name="tel"
                  placeholder="Enter telefon"
                  onChange={handleOnChange}
                  icon={<Call sx={{ color: 'gray', marginRight: '5px' }} />}
                  iconAlign="left"
                />
              </TextWrapper>
              <TextWrapper
                label="E-post"
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <TextField
                  name="email"
                  onChange={handleOnChange}
                  placeholder="Enter e-post"
                  icon={<Email sx={{ color: 'gray', marginRight: '5px' }} />}
                  iconAlign="left"
                />
              </TextWrapper>
            </RowBox>
          </CardLayout>
          <CardLayout title="Faktureringsuppgifter" index={2} description="">
            <TextWrapper
              label="Fakturasadress"
              sx={{ padding: '20px', width: '100%' }}
              labelPosition="top"
            >
              <TextField
                name="address"
                onChange={handleOnChange}
                placeholder="Enter address"
                icon={<HomeIcon sx={{ color: 'gray', marginRight: '5px' }} />}
                iconAlign="left"
              />
            </TextWrapper>
            <TextWrapper
              label="Fakturasadress 2"
              sx={{ padding: '20px', width: '100%' }}
              labelPosition="top"
            >
              <TextField
                name="addressAlt"
                placeholder="Enter address"
                onChange={handleOnChange}
                icon={<HomeIcon sx={{ color: 'gray', marginRight: '5px' }} />}
                iconAlign="left"
              />
            </TextWrapper>
            <RowBox>
              <TextWrapper
                label="Post Nr"
                sx={{ padding: '20px', width: '100%' }}
                labelPosition="top"
              >
                <TextField
                  name="postNumber"
                  onChange={handleOnChange}
                  placeholder="Enter post nr"
                  icon={<MarkunreadMailboxIcon sx={{ color: 'gray', marginRight: '5px' }} />}
                  iconAlign="left"
                />
              </TextWrapper>
              <TextWrapper label="Ort" sx={{ padding: '20px', width: '100%' }} labelPosition="top">
                <TextField
                  name="location"
                  placeholder="Enter location"
                  onChange={handleOnChange}
                  icon={<LocationOn sx={{ color: 'gray', marginRight: '5px' }} />}
                  iconAlign="left"
                />
              </TextWrapper>
            </RowBox>
            <RowBox>
              <TextWrapper
                label="Betalningsvillkor"
                labelPosition="top"
                sx={{ padding: '20px', width: '50%' }}
              >
                <Dropdown
                  name="paymentTerm"
                  onChange={handleOnChange}
                  title="Välj"
                  icon={<Payment sx={{ color: 'gray', paddingLeft: '5px' }} />}
                  data={[
                    '0 dagar',
                    '10 dagar',
                    '15 dagar',
                    '20 dagar',
                    '30 dagar',
                    '45 dagar',
                    'Autogiro',
                    'Kontant',
                  ]}
                />
              </TextWrapper>
            </RowBox>
          </CardLayout>
          <Box sx={{ display: 'flex', flexFlow: 'row-reverse', padding: '15px' }}>
            <Button onClick={handleSubmit} disabled={isLoading} variant="contained">
              Spara
            </Button>
          </Box>
        </ColumnBox>
      </RowBox>
    </BaseModal>
  );
};

// TableWidget.tsx
import { WidgetLayout, WidgetProps } from '../layouts/WidgetLayout';
import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

/**
 * Defines the structure of a column in the table.
 */
export interface Column {
  header: string;
  accessor: string; // e.g., "customer.name"
  hideHeader?: boolean;
  align?: 'left' | 'right' | 'center';
  render?: (row: any) => React.ReactNode; // Optional render function
}

/**
 * Sort configuration type.
 */
interface SortConfig {
  key: string;
  direction: 'asc' | 'desc';
}

/**
 * Props for the TableWidget component, constrained to T extends object.
 */
interface TableProps<T extends object> extends WidgetProps {
  data: T[];
  columns: Column[];
  sortConfig?: SortConfig;
  onSort?: (key: string) => void;
  hideHeader?: boolean;
  /**
   * A callback invoked when a row is clicked. Receives the entire row object.
   * If not provided, rows are not clickable.
   */
  onRowClick?: (row: T) => void;
}

/**
 * Safely retrieve a nested value from an object based on a dot-notation accessor.
 */
function getNestedValue<T extends object>(obj: T, accessor: string): unknown {
  let current: unknown = obj;
  for (const key of accessor.split('.')) {
    if (typeof current !== 'object' || current === null) {
      return '';
    }
    const record = current as Record<string, unknown>;
    if (!(key in record)) {
      return '';
    }
    current = record[key];
  }
  return current;
}

export const TableWidget = <T extends object>({
  title,
  size,
  data,
  columns,
  sortConfig,
  onSort,
  hideHeader = true,
  onRowClick, // <--- NEW
}: TableProps<T>): React.ReactElement => {
  return (
    <WidgetLayout hideHeader={hideHeader} title={title} size={size} disablePadding>
      <TableContainer sx={{ borderRadius: hideHeader ? '8px' : '0px' }}>
        <Table>
          {/* Table Header */}
          <TableHead>
            <TableRow>
              {columns.map((col) => {
                const isSortable = Boolean(onSort);
                const isActiveSort = sortConfig?.key === col.accessor;

                return (
                  <StyledTableCell
                    key={col.header}
                    align={col.align || 'left'}
                    onClick={() => isSortable && onSort?.(col.accessor)}
                    sx={{ cursor: isSortable ? 'pointer' : 'default' }}
                  >
                    {col.header}
                    {isActiveSort && (
                      <span style={{ marginLeft: 4 }}>
                        {sortConfig?.direction === 'asc' ? '▲' : '▼'}
                      </span>
                    )}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {data.map((row, rowIndex) => {
              // If onRowClick is provided, make the row clickable
              const handleClick = onRowClick ? () => onRowClick(row) : undefined;

              return (
                <StyledTableRow
                  key={rowIndex}
                  onClick={handleClick}
                  hover={Boolean(onRowClick)} // visually highlight row on hover
                  sx={{
                    cursor: onRowClick ? 'pointer' : 'default',
                  }}
                >
                  {columns.map((col) => {
                    const value = col.render ? col.render(row) : getNestedValue(row, col.accessor);
                    return (
                      <StyledTableCell key={col.accessor} align={col.align || 'left'}>
                        {value as React.ReactNode}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </WidgetLayout>
  );
};

/**
 * Styled table cell
 */
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.customColors.color2,
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

/**
 * Styled table row
 */
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    '&:hover': {
      backgroundColor: theme.palette.action.focus,
    },
  },
  '&:nth-of-type(even)': {
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
